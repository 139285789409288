/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { formatAddress } from "../helpers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddMessageModal = ({ open, close, user, addMessage, companies, addresses }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    companyId: "",
    addressId: "",
    title: "",
    message: "",
    isActive: true,
    isGlobal: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const submitForm = async () => {
    const { title, message, isActive, isGlobal, addressId, companyId } = state;
    let selectedCompanyId = !companyId ? user.companyId : companyId;
    let selectedAddressId = !addressId ? user.addressId : addressId;
    try {
      if (!title || !message) {
        throw new Error("Veuillez remplir les champs titre et message!");
      }
      setIsLoading(true);
      const messageAdded = await Axios.post(
        `${process.env.REACT_APP_API_URL}/messages/add`,
        {
          userId: user.id,
          addressId: selectedAddressId,
          companyId: selectedCompanyId,
          title,
          message,
          isActive,
          isGlobal,
          user
        }
      );
      setIsLoading(false);
      addMessage(messageAdded.data);
      setSuccess("message ajoutée!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        setState({
          addressId: "",
          companyId: "",
          title: "",
          message: "",
          isActive: true,
          isGlobal: false,
        });
        close();
        setIsLoading(false);
      }, 1500);
    } catch (err) {
      console.log('err', err)
      setError(err.message);
      setIsLoading(false);
    }
  };

  const clientList = companies.map((company) => {
    return (
      <option
        key={company._id}
        value={company._id}
      >{`${company.nameCompany}`}</option>
    );
  });

  const addressListWpp = addresses.map((address) => {
    if (state.companyId === address.companyId) {
      return (
        <option key={address._id} value={address._id}>
          {formatAddress(
            address.nameAddress,
            address.street,
            address.zip,
            address.city
          )}
        </option>
      );
    } else {
      return 1;
    }
  });

  const addressListOfficer = addresses.map((address) => {
    if (user.companyId === address.companyId) {
      return (
        <option key={address._id} value={address._id}>
          {formatAddress(
            address.nameAddress,
            address.street,
            address.zip,
            address.city
          )}
        </option>
      );
    } else {
      return 1;
    }
  });

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Ajouter message
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", margin: "0 auto", color: "red" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", margin: "0 auto", color: "green" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <TextField
          onChange={handleChange}
          name="title"
          value={state.title}
          type="text"
          label="Titre du message"
          margin="normal"
          fullWidth
          variant="filled"
        />
        <FormControlLabel
          label="Diffusion"
          control={
            <Checkbox
              checked={state.isActive}
              onChange={handleCheck}
              name="isActive"
              color="primary"
            />
          }
        />
        <FormControlLabel
          label="Générale"
          control={
            <Checkbox
              checked={state.isGlobal}
              onChange={handleCheck}
              name="isGlobal"
              color="primary"
            />
          }
        />
        {user.status === 1 && (
          <>
            <FormControl style={{ marginBottom: "20px", width: "100%" }}>
              <InputLabel htmlFor="age-native-simple">Client:</InputLabel>
              <Select
                native
                value={state.companyId}
                onChange={handleChange}
                inputProps={{
                  name: "companyId",
                  id: "companyId",
                }}
              >
                <option
                  key="emptyClient"
                  value=""
                ></option>
                {clientList}
              </Select>
            </FormControl>
            <FormControl style={{ marginBottom: "20px", width: "100%" }}>
              <InputLabel htmlFor="age-native-simple">Adresse:</InputLabel>
              <Select
                disabled={state.companyId === ""}
                native
                value={state.addressId}
                onChange={handleChange}
                inputProps={{
                  name: "addressId",
                  id: "addressId",
                }}
              >
                <option
                  key="emptyAddress"
                  value=""
                ></option>
                {addressListWpp}
              </Select>
            </FormControl>
          </>
        )}
        {(user.status === 2 || user.status === 5) && (
          <FormControl style={{ marginBottom: "20px", width: "100%" }}>
            <InputLabel htmlFor="age-native-simple">Adresse:</InputLabel>
            <Select
              native
              value={state.addressId}
              onChange={handleChange}
              inputProps={{
                name: "addressId",
                id: "addressId",
              }}
            >
              <option
                key="emptyAddress"
                value=""
              ></option>
              {addressListOfficer}
            </Select>
          </FormControl>
        )}
        <TextField
          name="message"
          label="Message"
          multiline
          maxRows="4"
          fullWidth
          value={state.message}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={submitForm}
          color="primary"
        >
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMessageModal;
