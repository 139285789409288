import React from "react";
import { Container, Grid, CircularProgress } from "@material-ui/core";




const Loader = () => {
    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center" xs={12} style={{ height: "80vh" }}>
                <CircularProgress size={20} />
            </Grid>
        </Container>
    );
}

export default Loader;