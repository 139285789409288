//numNomRue, complementAdresse, codePostal, ville
export const formatAddress = (num, comp, pc, town) =>
  `${num} ${comp}, ${pc} ${town}`;

export const dateFormat = (date) => {
  if (date !== undefined && date !== null) {
    let format = date.slice(0, 10).split("-").reverse().join("-");
    return format;
  }
};

export const textFormat = (text, maxLength1, maxLength2, isSmallCard) => {
  let maxLength = isSmallCard ? maxLength1 : maxLength2;
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  } else {
    return text
  }
}

export const diffDaysFormat = (date) => {
  const date1 = new Date("01/01/1970");
  if (date !== undefined && date !== null) {
    let format = date.slice(0, 10).split("-").reverse();
    const date2 = new Date([format[1], format[0], format[2]].join("/"));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
    return diffDays;
  }
};

