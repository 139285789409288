import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../actions";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Container } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddressCard from "./AddressCard";
import ModifAddressModal from "./ModifAddressModal";
import AddAddressModal from "./AddAddressModal";
import Loader from "./Loader";

import NavMenu from "./Navs/NavMenu";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 7rem;
  padding: 1rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
`;

const AddressList = ({ location, history, user, logout }) => {
  const [modifModal, setModifModal] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { company } = location.state;
  useEffect(() => {
    try {
      const fetchData = async () => {
        const allAddresses = await axios.get(
          `${process.env.REACT_APP_API_URL}/address?id=${user.id}&token=${user.token}`
        );
        setAddresses(allAddresses.data);
        setIsLoading(false);
      }
      fetchData()
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        setError(errorMessage)
        setTimeout(() => {
          history.push("/");
          logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }, [history, logout, user.id, user.token])

  const addressClick = (address) => {
    setAddress(address);
    setModifModal(true);
  };

  const goToUsers = (address) => {
    setAddress(address);
    history.push({
      pathname: "/userList",
      state: { company, address },
    });
  };

  const addAddress = address => {
    setAddresses([...addresses, address])
  }

  const updateAddress = address => {
    let addresseIds = addresses.map(c => c._id)
    let index = addresseIds.indexOf(address._id)
    let add = [...addresses]
    add[index] = address
    setAddresses(add)
  }

  // company existe uniquement pour les wpp qui peuvent gérer plusieurs companies 
  // sinon c'est le user companyId qui est selectionné pour afficher les addresses et en ajouter
  const companyId = company?._id || user.companyId
  let addressFiltered = addresses.filter(
    (address) => address.companyId === companyId
  );

  const addressesCard = addressFiltered.map((address) => {
    return (
      <AddressCard
        key={address._id}
        address={address}
        addressClick={addressClick}
        goToUsers={goToUsers}
      />
    );
  });

  return (
    <Wrapper>
      <NavMenu />
      {isLoading ?
        <>
          <Grid container justifyContent="flex-end">
            {error && (
              <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                {error}
              </em>
            )}
          </Grid>
          <Loader />
        </> :
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Title variant="h4">
                Adresses{user.status === 1 && ` de ${company.nameCompany}`}
              </Title>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="flex-end">
                {user.status === 1 && (
                  <Button
                    onClick={() => history.push("/clientListScreen")}
                    variant="contained"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                    style={{
                      background: "#1C3144",
                      color: "#FFF",
                      outline: "none",
                      margin: "0.5rem",
                    }}
                  >
                    Retour
                  </Button>
                )}
                {(user.status === 1 || user.status === 2 || user.status === 5) && (<Button
                  size="small"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => setAddAddressModal(true)}
                  variant="contained"
                  style={{
                    background: "#1C3144",
                    color: "#FFF",
                    outline: "none",
                    margin: "0.5rem",
                  }}
                >
                  Ajouter Adresse
                </Button>)}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            {addressesCard}
          </Grid>
        </Container>
      }
      <ModifAddressModal
        updateAddress={updateAddress}
        user={user}
        address={address}
        open={modifModal}
        close={() => setModifModal(false)}
      />
      <AddAddressModal
        addAddress={addAddress}
        user={user}
        companyId={companyId}
        address={address}
        open={addAddressModal}
        close={() => setAddAddressModal(false)}
      />
    </Wrapper>
  );
};

// ZONE REDUX
const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressList));
