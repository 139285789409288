import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";

import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModifUserModal = ({ open, close, user, userClicked, updateUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobilePhone: "",
    officePhone: "",
    whatsApp: false,
    status: null,
  });

  useEffect(() => {
    setState({
      firstname: userClicked.firstname,
      lastname: userClicked.lastname,
      email: userClicked.email,
      mobilePhone: userClicked.mobilePhone,
      officePhone: userClicked.officePhone,
      whatsApp: userClicked.whatsApp ? userClicked.whatsApp : false,
      status: userClicked.status,
    });
  }, [userClicked]);

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setSubmit(true);
    setState({ ...state, [name]: value });
  };

  const submitForm = async () => {
    const {
      firstname,
      lastname,
      email,
      mobilePhone,
      officePhone,
      whatsApp,
      status,
    } = state;
    try {
      if (!firstname || !lastname || !email) {
        throw new Error("Veuillez remplir tous les champs!");
      }
      if (
        firstname === userClicked.firstname &&
        lastname === userClicked.lastname &&
        email === userClicked.email &&
        mobilePhone === userClicked.mobilePhone &&
        officePhone === userClicked.officePhone &&
        whatsApp === userClicked.whatsApp &&
        status === userClicked.status
      ) {
        throw new Error("Les informations sont identiques!");
      }
      setIsLoading(true);
      setSubmit(false);
      const updatedUser = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/update/${userClicked._id}`,
        {
          ...state,
          user
        }
      );
      updateUser(updatedUser.data);
      setSuccess("Données mises à jour!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
      }, 1500);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const wppOptions = (
    <>
      <option value={2}>Company Officer</option>
      <option value={3}>Company Manager</option>
      <option value={4}>Utilisateur</option>
      <option value={5}>Company Officer Autonome</option>
      <option value={6}>Company Manager Autonome</option>
    </>
  );

  const officerOptions = (
    <>
      <option value={3}>Company Manager</option>
      <option value={4}>Utilisateur</option>
    </>
  );

  const officerAutonomeOptions = (
    <>
      <option value={6}>Company Manager</option>
      <option value={4}>Utilisateur</option>
    </>
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Modifier utilisateur
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <Grid container justifyContent="space-between">
          <TextField
            onChange={handleChange}
            name="firstname"
            value={state.firstname}
            type="text"
            label="Prénom"
            style={{ marginRight: "1rem" }}
            margin="normal"
            variant="filled"
          />
          <TextField
            onChange={handleChange}
            name="lastname"
            value={state.lastname}
            type="text"
            label="Nom"
            margin="normal"
            variant="filled"
          />
        </Grid>
        <TextField
          onChange={handleChange}
          name="email"
          value={state.email}
          type="email"
          label="Email"
          margin="normal"
          variant="filled"
          fullWidth
        />
        <Grid container justifyContent="space-between">
          <TextField
            onChange={handleChange}
            name="mobilePhone"
            value={state.mobilePhone}
            type="text"
            label="Téléphone mobile"
            variant="filled"
            margin="normal"
          />
          <TextField
            onChange={handleChange}
            name="officePhone"
            value={state.officePhone}
            type="text"
            label="Téléphone bureau"
            variant="filled"
            margin="normal"
          />
        </Grid>
      </DialogContent>
      <Grid container justifyContent="space-between">
        <Select
          native
          value={state.whatsApp}
          name="whatsApp"
          onChange={handleChange}
          style={{ margin: "2rem auto", width: "40%" }}
        >
          {/* <option value={""}>Utilisez-vous Whatsapp</option> */}
          <option value={false}>Non</option>
          <option value={true}>Oui</option>
        </Select>
        {(user.status < 3 || user.status === 5) && (
          <Select
            native
            value={state.status}
            name="status"
            onChange={handleChange}
            style={{ margin: "2rem auto", width: "40%" }}
          >
            <option value="">Choisir le statut</option>
            {user.status === 1
              ? wppOptions
              : user.status === 2
                ? officerOptions
                : officerAutonomeOptions}
          </Select>
        )}
      </Grid>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button
          disabled={!submit}
          variant="outlined"
          onClick={submitForm}
          color="primary"
        >
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifUserModal;
