import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ModifIncidentModal from "../ModifIncidentModal";
import IncidentCard from "../IncidentCard";
import { Container } from "@material-ui/core";
import NavMenu from "../Navs/NavMenu";
import Loader from "../Loader";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Carousel from "react-material-ui-carousel";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 7rem;
  padding: 1rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
`;

class NewsScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      incident: {},
      currentStatus: {},
      historicalStatus: {},
      openIncident: false,
      readOnly: true,
      treat: false,
      isFiltered: false,
      filteredIncidents: [],
      filterUrgence: "Toutes",
      filterAddress: "Toutes",
      filterCompany: "Toutes",
      filterExpertise: "Toutes",
      filterDirection: "Toutes",
      isLoading: true,
      success: "",
      error: "",
      incidents: [],
      messages: [],
      addresses: [],
      companies: [],
      users: []
    };
  }

  // Charge tous les incidents dans le state pour pouvoir les filtrer
  async componentDidMount() {
    try {
      const incidents = await axios.get(
        `${process.env.REACT_APP_API_URL}/incidents?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const messages = await axios.get(
        `${process.env.REACT_APP_API_URL}/messages?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const addresses = await axios.get(
        `${process.env.REACT_APP_API_URL}/address?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const companies = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const users = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      this.setState({
        incidents: incidents.data,
        filteredIncidents: incidents.data,
        messages: messages.data,
        addresses: addresses.data,
        companies: companies.data,
        users: users.data,
        isLoading: false
      });
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        this.setState({ error: errorMessage })
        setTimeout(() => {
          this.props.history.push("/");
          this.props.logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }

  updateIncident = incident => {
    let incIds = this.state.incidents.map(i => i._id)
    let index = incIds.indexOf(incident._id)
    let inc = [...this.state.incidents]
    inc[index] = incident
    this.setState({ incidents: inc })
  }

  // Fonction de tri des incidents
  filterIncident = async (event) => {
    const { name, value } = event.target;
    // Recuperation des valeurs actuelles des différents filtres
    let urgence = this.state.filterUrgence;
    let address = this.state.filterAddress;
    let company = this.state.filterCompany;
    let expertise = this.state.filterExpertise;
    let direction = this.state.filterDirection;
    // Modification de la valeur du filtre actionné
    switch (name) {
      case "urgence":
        urgence = value;
        this.setState({
          filterUrgence: value,
        });
        break;
      case "address":
        address = value;
        this.setState({
          filterAddress: value,
        });
        break;
      case "company":
        company = value;
        this.setState({
          filterCompany: value,
        });
        break;
      case "expertise":
        expertise = value;
        this.setState({
          filterExpertise: value,
        });
        break;
      case "direction":
        direction = value;
        this.setState({
          filterDirection: value,
        });
        break;
      default:
        break;
    }
    // Envoi des valeurs de filtrages avec la valeur modifiée
    this.createFilter(urgence, address, company, expertise, direction);
  };

  // Création de la liste des incidents filtrés
  createFilter = async (urgence, address, company, expertise, direction) => {
    // Récuperation de tous les incdents pour les passer dans tous les filtres
    // Pour chaque filtre si la valeur est "Toutes" les incidents ne sont pas filtrés
    let filteredIncidents = this.state.incidents;
    if (urgence !== "Toutes") {
      filteredIncidents = filteredIncidents.filter(
        (incident) => incident.urgence === urgence
      );
    }
    if (address !== "Toutes") {
      filteredIncidents = filteredIncidents.filter(
        (incident) => incident.addressId === address
      );
    }
    if (company !== "Toutes") {
      filteredIncidents = filteredIncidents.filter(
        (incident) => incident.companyId === company
      );
    }
    if (expertise !== "Toutes") {
      filteredIncidents = filteredIncidents.filter(
        (incident) => incident.expertise === expertise
      );
    }
    if (direction !== "Toutes") {
      filteredIncidents = filteredIncidents.filter(
        (incident) => incident.direction === direction
      );
    }
    // Renvoi dans le state tous les incidents qui correspondent aux différents filtres
    this.setState({ filteredIncidents });
  };

  // Configure le state pour ouvrir l'incident en mode lecture
  readOnlyModal = (incident) => {
    this.setState({
      openIncident: true,
      incident,
      readOnly: true,
      treat: false,
      currentStatus: incident.currentStatus[0],
      historicalStatus: incident.historicalStatus,
    });
  };

  // Configure le state pour ouvrir l'incident en mode modification
  updateModal = (incident) => {
    this.setState({
      openIncident: true,
      incident,
      readOnly: false,
      treat: false,
      currentStatus: incident.currentStatus[0],
      historicalStatus: incident.historicalStatus,
    });
  };

  // Configure le state pour ouvrir l'incident en mode traitement (évolution du status de l'incident)
  processModal = (incident) => {
    this.setState({
      openIncident: true,
      incident,
      readOnly: false,
      treat: true,
      currentStatus: incident.currentStatus[0],
      historicalStatus: incident.historicalStatus,
    });
  };

  render() {
    // Récuperation de la liste de tous les messages pour filtrer
    // ceux qui sont actifs et global ou correspondant à l'adresse
    let messageActive = this.state.messages.filter(
      (message) =>
        message.isActive &&
        (message.isGlobal || message.addressId === this.props.user.addressId)
    );

    // Recuperation des incidents filtrés pour les trier par date de création
    const sortedIncidents = this.state.filteredIncidents.sort(function (a, b) {
      let aDate =
        a.historicalStatus.length === 0
          ? a.currentStatus[0].dateOpen
          : a.historicalStatus[0].dateOpen;
      let bDate =
        b.historicalStatus.length === 0
          ? b.currentStatus[0].dateOpen
          : b.historicalStatus[0].dateOpen;
      return new Date(bDate) - new Date(aDate);
    });
    // Recuperation des incidents triés pour selectionner ceux que l'utilisateur peux voir
    // Et création des cartes d'incidents
    const incidents = sortedIncidents.map((incident, i) => {
      let userIncident = this.state.users.filter(
        (users) => users._id === incident.userId
      );
      if (userIncident.length !== 1) {
        userIncident = [{ firstname: false }];
      }
      return (
        <IncidentCard
          key={incident._id}
          userIncident={userIncident[0]}
          incident={incident}
          user={this.props.user}
          readOnlyModal={this.readOnlyModal}
          updateModal={this.updateModal}
          processModal={this.processModal}
        />
      );
    });

    // Création de la liste des adresses pour le dropdown de filtre
    const addressList = this.state.addresses.filter(add => this.state.filterCompany === "Toutes" ? true : add.companyId === this.state.filterCompany).map((address) => {
      return <MenuItem key={address._id} value={address._id}>{address.nameAddress}</MenuItem>;
    });

    // Création de la liste des company pour le dropdown de filtre
    const companyList = this.state.companies.map((company) => {
      return <MenuItem key={company._id} value={company._id}>{company.nameCompany}</MenuItem>;
    });

    const selectedCompany = this.state.companies.length === 1 ? this.state.companies : this.state.companies.filter(el => el._id === this.state.filterCompany)
    let directionList = [];
    if (selectedCompany.length === 1) {
      directionList = selectedCompany[0].directions.map((direction) => {
        return <MenuItem key={direction} value={direction}>{direction}</MenuItem>;
      });
    } else {
      directionList = <MenuItem key={"emptyDirectionKey"} value="">Aucune</MenuItem>
    }

    // Création du caroussel des messages
    function createCarousel(messagesList) {
      return (
        <Carousel autoPlay={true} interval={6000}>
          {messagesList.map((item) => (
            <Item key={item._id} item={item} />
          ))}
        </Carousel>
      );
    }

    // Création des items du caroussel de message
    function Item(props) {
      return (
        <Paper
          style={{
            padding: "0.7rem",
          }}
        >
          <h4>{props.item.title}</h4>
          <p>{props.item.message}</p>
        </Paper>
      );
    }

    return (
      <Wrapper>
        <NavMenu />
        {this.state.isLoading ?
          <>
            <Grid container justifyContent="flex-end">
              {this.state.error && (
                <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                  {this.state.error}
                </em>
              )}
            </Grid>
            <Loader />
          </> :
          <Container>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <Title variant="h4">Des news de vos bureaux</Title>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-end">
                  {this.props.user.status !== 4 && (
                    <Button
                      size="small"
                      onClick={() => this.props.history.push("/statScreen")}
                      variant="contained"
                      style={{
                        background: "#1C3144",
                        color: "#FFF",
                        outline: "none",
                        margin: "0.5rem",
                      }}
                    >
                      Statistiques
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {this.state.messages.length > 0 &&
                  createCarousel(messageActive)}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              {this.state.error && (
                <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                  {this.state.error}
                </em>
              )}
              {this.state.success && (
                <em
                  style={{ fontWeight: "bold", color: "green", margin: "auto" }}
                >
                  {this.state.success}
                </em>
              )}
            </Grid>
            <Grid container justifyContent="flex-start">
              {this.props.user.status === 1 &&
                this.state.companies.length > 1 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl

                      style={{ margin: "1rem", minWidth: "80%" }}
                    >
                      <InputLabel id="company">Companie</InputLabel>
                      <Select
                        labelId="company"
                        id="select-outlined"
                        value={this.state.filterCompany}
                        onChange={this.filterIncident}
                        label="Companie"
                        name="company"
                      >
                        <MenuItem value="Toutes">Toutes</MenuItem>
                        {companyList}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              {((this.props.user.status === 5 ||
                this.props.user.status < 3) &&
                this.state.addresses.length > 1) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl

                      style={{ margin: "1rem", minWidth: "80%" }}
                    >
                      <InputLabel id="address">Adresse</InputLabel>
                      <Select
                        labelId="address"
                        id="select-outlined"
                        value={this.state.filterAddress}
                        onChange={this.filterIncident}
                        label="Adresse"
                        name="address"
                      >
                        <MenuItem value="Toutes">Toutes</MenuItem>
                        {addressList}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              <Grid item xs={12} sm={6} md={3}>
                <FormControl

                  style={{ margin: "1rem", minWidth: "80%" }}
                >
                  <InputLabel id="urgence">Urgence</InputLabel>
                  <Select
                    labelId="urgence"
                    id="select-outlined"
                    value={this.state.filterUrgence}
                    onChange={this.filterIncident}
                    label="Urgence"
                    name="urgence"
                  >
                    <MenuItem value="Toutes">Toutes</MenuItem>
                    <MenuItem value="Très urgent">Très urgent</MenuItem>
                    <MenuItem value="Urgent">Urgent</MenuItem>
                    <MenuItem value="Peu urgent">Peu urgent</MenuItem>
                    <MenuItem value="Pas urgent">Pas urgent</MenuItem>
                    <MenuItem value="Si possible">Si possible</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl

                  style={{ margin: "1rem", minWidth: "80%" }}
                >
                  <InputLabel id="expertise">Expertise</InputLabel>
                  <Select
                    labelId="expertise"
                    id="select-outlined"
                    value={this.state.filterExpertise}
                    onChange={this.filterIncident}
                    label="Expertise"
                    name="expertise"
                  >
                    <MenuItem value="Toutes">Toutes</MenuItem>
                    <MenuItem value="Propreté">Propreté</MenuItem>
                    <MenuItem value="Sécurité">Sécurité</MenuItem>
                    <MenuItem value="Détection et Extinction Incendie">Détection et Extinction Incendie</MenuItem>
                    <MenuItem value="Climatisation et Chauffage">
                      Climatisation et Chauffage
                    </MenuItem>
                    <MenuItem value="Wifi et Téléphonie">
                      Wifi et Téléphonie
                    </MenuItem>
                    <MenuItem value="Ascenseurs">Ascenseurs</MenuItem>
                    <MenuItem value="Courrier">Courrier</MenuItem>
                    <MenuItem value="Cafétérias">Cafétérias</MenuItem>
                    <MenuItem value="Plomberie et Electricité">
                      Plomberie, Electricité, Serrurerie, Menuiserie
                    </MenuItem>
                    <MenuItem value="Mobilier, Décoration et Plantes">
                      Mobilier, Décoration et Plantes
                    </MenuItem>
                    <MenuItem value="Espaces verts">Espaces verts</MenuItem>
                    <MenuItem value="Bail">Bail</MenuItem>
                    <MenuItem value="Déménagements internes">
                      Aménagements et Déménagements
                    </MenuItem>
                    <MenuItem value="Autres">Autres</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {directionList.length > 1 && (<Grid item xs={12} sm={6} md={3}>
                <FormControl

                  style={{ margin: "1rem", minWidth: "80%" }}
                >
                  <InputLabel id="direction">Direction</InputLabel>
                  <Select
                    labelId="direction"
                    id="select-outlined"
                    value={this.state.filterDirection}
                    onChange={this.filterIncident}
                    label="Direction"
                    name="direction"
                  >
                    <MenuItem value="Toutes">Toutes</MenuItem>
                    {directionList}
                  </Select>
                </FormControl>
              </Grid>)}
            </Grid>
            <Grid container justifyContent="space-around">
              {incidents}
            </Grid>
          </Container>
        }
        <ModifIncidentModal
          user={this.props.user}
          updateIncident={this.updateIncident}
          readOnly={this.state.readOnly}
          treat={this.state.treat}
          status={this.props.user.status}
          companies={this.state.companies}
          open={this.state.openIncident}
          close={() => {
            this.setState({
              openIncident: false,
              // filteredIncidents: this.props.user.incidents,
            });
            this.createFilter(
              this.state.filterUrgence,
              this.state.filterAddress,
              this.state.filterCompany,
              this.state.filterExpertise,
              this.state.filterDirection
            );
          }}
          incident={this.state.incident}
          currentStatus={this.state.currentStatus}
          historicalStatus={this.state.historicalStatus}
        />
      </Wrapper>
    );
  }
}
// ZONE REDUX
const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsScreen);
