import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, green } from "@material-ui/core/colors";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CreateIcon from "@material-ui/icons/Create";
import { dateFormat } from "../helpers";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F1F1F1",
    margin: 15,
    width: 345,
    height: 250,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  Red: {
    backgroundColor: red[500],
  },
  Green: {
    backgroundColor: green[500],
  },
  header: {
    color: "rgb(55, 156, 136)",
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  date: {
    color: "#1C3144",
  },
  name: {
    fontSize: "1rem",
  },
  status: {
    color: "#1C3144",
    fontWeight: "500",
  },
}));

export default function ClientCard({ company, clientClick, goToAddresses }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        // avatar={
        //   <Avatar
        //     aria-label=""
        //     className={isActive ? classes.Green : classes.Red}
        //   >
        //     <MessageIcon />
        //   </Avatar>
        // }
        title={
          <Typography className={classes.header} variant="body2" component="p">
            {company.nameCompany}
          </Typography>
        }
        subheader={
          <Typography className={classes.date} variant="body2" component="p">
            Du {dateFormat(company.dateStartContract)} au{" "}
            {dateFormat(company.dateEndContract)}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          N° de contrat : {company.numContract}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Nombre d'employés : {company.numOfEmployeesTotal}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary" component="p">
          Message diffusé : {isActive ? " Oui" : " Non"}
        </Typography> */}
        {/* <Typography variant="body2" color="textSecondary" component="p">
          Message général : {isGlobal ? " Oui" : " Non"}
        </Typography> */}
        <Divider />
        <br />
        {/* <Typography variant="body2" color="textSecondary" component="p">
          {messageFormat}
        </Typography> */}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="update" onClick={() => clientClick(company)}>
          <CreateIcon />
        </IconButton>
        <IconButton aria-label="address" onClick={() => goToAddresses(company)}>
          <LocationCityIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
