import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, green } from "@material-ui/core/colors";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { dateFormat } from "../helpers";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F1F1F1",
    margin: 15,
    width: 345,
    height: 250,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  Red: {
    backgroundColor: red[500],
  },
  Green: {
    backgroundColor: green[500],
  },
  header: {
    color: "rgb(55, 156, 136)",
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  date: {
    color: "#1C3144",
  },
  name: {
    fontSize: "1rem",
  },
  status: {
    color: "#1C3144",
    fontWeight: "500",
  },
}));

export default function UserCard({ user, modifUser, deleteUser }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label=""
            className={user.firstname ? classes.Green : classes.Red}
          ></Avatar>
        }
        title={
          <Typography className={classes.header} variant="body2" component="p">
            {user.firstname} {user.lastname}
          </Typography>
        }
        subheader={
          <Typography className={classes.date} variant="body2" component="p">
            inscrit le {dateFormat(user.dateInit)}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Statut : {user.status}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Email : {user.email}
        </Typography>
        <Divider />
        <br />
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="update" onClick={() => modifUser(user)}>
          <CreateIcon />
        </IconButton>
        <IconButton aria-label="deleteUser" onClick={() => deleteUser(user)}>
          <DeleteOutlineIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
