import React from "react";
import Axios from "axios";
import { Alert } from "reactstrap";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ClientList from "../ClientList";

class ClientListScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      wppList: [],
    };
  }

  componentDidMount() {
    if (this.props.user.status === 1 && this.state.wppList.length === 0) {
      let ctx = this;
      Axios
        .get(`${process.env.REACT_APP_API_URL}/users/wpp?id=${this.props.user.id}&token=${this.props.user.token}`)
        .then(function (response) {
          ctx.setState({ wppList: response.data });
        })
        .catch(function (error) {
          Alert("Erreur de chargement de la liste des wpp");
          console.log(error);
        });
    }
  }

  render() {
    return (
      <ClientList
        user={this.props.user}
        wppList={this.state.wppList}
      />
    );
  }
}

// ZONE REDUX
const mapStateToProps = ({ user }) => ({
  user,
});

export default withRouter(connect(mapStateToProps, null)(ClientListScreen));
