/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserListModal = ({ open, close, companyId, addressId, addUserList, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    emailList: ""
  });

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const submitForm = async () => {
    const { emailList } = state;

    try {
      if (!emailList) {
        throw new Error("Veuillez remplir le champ!");
      }
      setIsLoading(true);
      const addedUserList = await Axios.post(
        `${process.env.REACT_APP_API_URL}/users/addList`,
        {
          emailList,
          companyId,
          addressId,
          user
        }
      );
      addUserList(addedUserList.data);
      setSuccess("Liste d'utilisateurs ajoutée avec succès!");
      setTimeout(() => {
        close();
        setSuccess("");
        setError("");
        setState({ emailList: "" });
      }, 1500);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Ajouter une liste d'utilisateurs
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <TextField
          required
          onChange={handleChange}
          value={state.emailList}
          name="emailList"
          type="email"
          label="Liste d'emails"
          margin="normal"
          placeholder="emails"
          fullWidth
          multiline
          maxRows="15"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button variant="outlined" onClick={submitForm} color="primary">
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserListModal;
