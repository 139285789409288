import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import axios from "axios";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";

import "../style/UserRegistration.css";
import { withRouter } from "react-router-dom";

class ResetPasswordScreen extends Component {
  constructor() {
    super();
    var loc = new URL(window.location);
    var searchParams = new URLSearchParams(loc.search);
    this.state = {
      email: searchParams.get("email"),
      token: searchParams.get("token"),
      password: "",
      confirmPassword: "",
      errorMessage: "",
      isLoading: false,
      error: "",
      success: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, error: "" });
  };

  submitForm = async () => {
    const { email, token, password, confirmPassword } = this.state;
    this.setState({ isLoading: true });
    try {
      if (password === "" || confirmPassword === "") {
        throw new Error("Remplissez correctement tous les champs");
      }
      if (password !== confirmPassword) {
        throw new Error(
          "Le mot de passe et sa confirmation ne sont pas identique!"
        );
      }
      if (password.length < 8) {
        throw new Error(
          "Votre mot de passe doit contenir au moins 8 caractères!"
        );
      }
      if (!/.*[a-z]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins une minuscule!"
        );
      }
      if (!/.*[A-Z]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins une majuscule!"
        );
      }
      if (!/.*[0-9]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins un chiffre!"
        );
      }
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/resetPassword/${token}`,
        {
          email,
          password,
          token,
        }
      );
      this.setState({ success: "Enregistrement effectué" });
      setTimeout(() => {
        this.setState({ success: "" });
        this.props.history.push("/");
      }, 1500);
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Jumbotron
        className="Jumbotron1"
        style={{ height: "100vh", marginBottom: "0px" }}
      >
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <img
              style={{ width: "80%" }}
              src={require("../../logoFWPv2.png")}
              alt="Logo"
            />
            <DialogTitle
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                color: "#379C88",
              }}
            >
              CHANGEMENT DE VOTRE MOT DE PASSE
            </DialogTitle>
            <FormControl style={{ width: "80%", marginBottom: "15px" }}>
              <TextField
                name="password"
                id="password"
                type="password"
                label="Nouveau mot de passe"
                defaultValue={this.state.password}
                value={this.state.password}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "80%", marginBottom: "15px" }}>
              <TextField
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                label="Confirmation de votre nouveau mot de passe"
                defaultValue={this.state.confirmPassword}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
            </FormControl>
            {this.state.error && (
              <em
                style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}
              >
                {this.state.error}
              </em>
            )}
            {this.state.success && (
              <em
                style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}
              >
                {this.state.success}
              </em>
            )}
          </Grid>
          <Grid container justifyContent="flex-end">
            <FormControl style={{ width: "20%" }}>
              <button
                disabled={this.state.isLoading}
                className="form-contact-button"
                onClick={this.submitForm}
              >
                {this.state.isLoading ? "Envoi..." : "Valider"}
              </button>
            </FormControl>
          </Grid>
        </Container>
      </Jumbotron>
    );
  }
}
export default withRouter(ResetPasswordScreen);
