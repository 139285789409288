/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteUserModal = ({ open, close, user, userClicked, deleteUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });

  useEffect(() => {
    setState({
      firstname: userClicked.firstname,
      lastname: userClicked.lastname,
      email: userClicked.email,
    });
  }, [userClicked]);

  const delUser = async () => {
    setIsLoading(true);
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/users/delete?id=${userClicked._id}&userId=${user.id}&token=${user.token}`
      );
      deleteUser(userClicked);
      setSuccess("Utilisateur supprimé!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
        setIsLoading(false);
      }, 1500);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Voulez-vous vraiment supprimer{" "}
        {state.firstname ? `${state.firstname} ${state.lastname}` : state.email}
      </DialogTitle>
      {userClicked.status !== 4 && user.status >= userClicked.status && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          Vous ne disposez pas de droit suffisant pour suppimer cette
          utilisateur
        </em>
      )}
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button
          disabled={
            isLoading ||
            (userClicked.status !== 4 && user.status >= userClicked.status)
          }
          variant="outlined"
          onClick={delUser}
          color="primary"
        >
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
