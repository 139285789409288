/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userActions } from "../actions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfilModal = ({ open, close, user, updateProfil }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobilePhone: "",
    officePhone: "",
    whatsApp: false
  });

  useEffect(() => {
    setState({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      mobilePhone: user.mobilePhone,
      officePhone: user.officePhone,
      whatsApp: user.whatsapp
    });
  }, [user]);

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setSubmit(true);
    setState({ ...state, [name]: value });
  };

  const submitForm = async () => {
    const {
      firstname,
      lastname,
      email,
      mobilePhone,
      officePhone,
      whatsApp
    } = state;

    try {
      if (!firstname || !lastname || !email) {
        throw new Error("Veuillez remplir tous les champs!");
      }

      if (
        firstname === user.firstname &&
        lastname === user.lastname &&
        email === user.email &&
        mobilePhone === user.mobilePhone &&
        officePhone === user.officePhone &&
        whatsApp === user.whatsapp
      ) {
        throw new Error("Les informations sont identiques!");
      }

      setIsLoading(true);
      setSubmit(false);
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/update/${user.id}`,
        {
          firstname,
          lastname,
          email,
          mobilePhone,
          officePhone,
          whatsApp,
          user
        }
      );
      updateProfil(
        firstname,
        lastname,
        email,
        mobilePhone,
        officePhone,
        whatsApp
      );
      setSuccess("Données mises à jour!");
      setTimeout(() => {
        close();
        setSuccess("");
        setError("");
      }, 1500);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Modifier votre profil
      </DialogTitle>
      {error && <em style={{ color: "red", margin: "0 auto" }}>{error}</em>}
      {success && (
        <em style={{ color: "green", margin: "0 auto" }}>{success}</em>
      )}
      <DialogContent>
        <Grid container justifyContent="space-between">
          <TextField
            onChange={handleChange}
            name="firstname"
            value={state.firstname}
            type="text"
            label="Prénom"
            style={{ marginRight: "1rem" }}
            margin="normal"
            variant="filled"
          />
          <TextField
            onChange={handleChange}
            name="lastname"
            value={state.lastname}
            type="text"
            label="Nom"
            margin="normal"
            variant="filled"
          />
        </Grid>

        <TextField
          onChange={handleChange}
          name="email"
          value={state.email}
          type="email"
          label="Email"
          margin="normal"
          variant="filled"
          fullWidth
        />
        <Grid container justifyContent="space-between">
          <TextField
            onChange={handleChange}
            name="mobilePhone"
            value={state.mobilePhone}
            type="text"
            label="Téléphone mobile"
            variant="filled"
            margin="normal"
          />
          <TextField
            onChange={handleChange}
            name="officePhone"
            value={state.officePhone}
            type="text"
            label="Téléphone bureau"
            variant="filled"
            margin="normal"
          />
        </Grid>
      </DialogContent>
      <Select
        native
        value={state.whatsApp}
        name="whatsApp"
        onChange={handleChange}
        style={{ margin: "2rem auto" }}
      >
        <option value="">Utilisez-vous Whatsapp</option>
        <option value={false}>Non</option>
        <option value={true}>Oui</option>
      </Select>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button
          disabled={!submit}
          variant="outlined"
          onClick={submitForm}
          color="primary"
        >
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  updateProfil: userActions.updateProfil
};

export default connect(null, mapDispatchToProps)(ProfilModal);
