import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, green } from "@material-ui/core/colors";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MessageIcon from "@material-ui/icons/Message";
import CreateIcon from "@material-ui/icons/Create";
import { dateFormat } from "../helpers";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F1F1F1",
    margin: 15,
    width: 345,
    height: window.innerWidth < 400 ? 350 : 310,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  Red: {
    backgroundColor: red[500],
  },
  Green: {
    backgroundColor: green[500],
  },
  header: {
    color: "rgb(55, 156, 136)",
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  date: {
    color: "#1C3144",
  },
  name: {
    fontSize: "1rem",
  },
  status: {
    color: "#1C3144",
    fontWeight: "500",
  },
}));

export default function MessageCard({
  messageObj,
  messageClick,
  companyMessage,
  addressMessage,
  userStatus,
}) {
  const { title, message, date, isActive, isGlobal } = messageObj;
  const classes = useStyles();

  let messageFormat = message;
  if (message.length > 45) {
    messageFormat =
      window.innerWidth < 400
        ? message.slice(0, 100) + "..."
        : message.slice(0, 120) + "...";
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label=""
            className={isActive ? classes.Green : classes.Red}
          >
            <MessageIcon />
          </Avatar>
        }
        title={
          <Typography className={classes.header} variant="body2" component="p">
            {title}
          </Typography>
        }
        subheader={
          <Typography className={classes.date} variant="body2" component="p">
            {dateFormat(date)}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        {userStatus === 1 && (
          <Typography variant="body2" color="textSecondary" component="p">
            Client : {companyMessage?.nameCompany}
          </Typography>
        )}
        {(userStatus === 2 || userStatus === 5) && (
          <Typography variant="body2" color="textSecondary" component="p">
            Adresse : {addressMessage?.nameAddress}
          </Typography>
        )}
        {/* <Typography variant="body2" color="textSecondary" component="p">
          Message diffusé : {isActive ? " Oui" : " Non"}
        </Typography> */}
        <Typography variant="body2" color="textSecondary" component="p">
          Message général : {isGlobal ? " Oui" : " Non"}
        </Typography>
        <Divider />
        <br />
        <Typography variant="body2" color="textSecondary" component="p">
          {messageFormat}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="update"
          onClick={() => messageClick(messageObj, "modifModal")}
        >
          <CreateIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => messageClick(messageObj, "deleteModal")}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
