import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import NavMenu from "../Navs/NavMenu";
import Typography from "@material-ui/core/Typography";
import { diffDaysFormat } from "../../helpers";
import Loader from "../Loader";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 5.5rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
  padding: 1.5rem;
  text-align: center;
`;

const SubTitle = styled(Typography)`
  color: #1c3144;
  padding: 0.5rem;
  padding-left: 2.5rem;
  text-align: start;
`;

class StatScreen extends Component {
  constructor() {
    super();
    this.state = {
      incidents: [],
      isLoading: true,
      error: "",
      incidentDataUrgence: [
        { name: "Très Urgent", value: 0, color: "#F44336" },
        { name: "Urgent", value: 0, color: "#EF6C00" },
        { name: "Peu Urgent", value: 0, color: "#FBC02D" },
        { name: "Pas Urgent", value: 0, color: "#4CAF50" },
        { name: "Si possible", value: 0, color: "#2196F3" },
      ],
      incidentDataStatus: [
        { name: "Signalé", incident: 0 },
        { name: "En charge", incident: 0 },
        { name: "Planifiée", incident: 0 },
        { name: "Réalisée", incident: 0 },
        { name: "Clos", incident: 0 },
      ],
    };
  }

  barStackTypeCreate = (addressList) => {
    let stackList = [];
    let colorList = [
      "#fad390",
      "#6a89cc",
      "#b8e994",
      "#f6b93b",
      "#e55039",
      "#4a69bd",
      "#78e08f",
      "#fa983a",
      "#1e3799",
      "#b71540",
      "#a5b1c2",
      "#82ccdd",
      "#eb3b5a",
      "#20bf6b",
      "#a55eea",
      "#4b6584",
      "#fa8231",
      "#2c2c54",
      "#f7f1e3",
      "#b33939",
    ];
    let i = 0;
    addressList.forEach((address) => {
      stackList.push(
        <Bar key={address} dataKey={address} stackId="a" fill={colorList[i]} />
      );
      i > 19 ? (i = 0) : i++;
    });
    return stackList;
  };

  async componentDidMount() {
    try {
      const incidents = await axios.get(
        `${process.env.REACT_APP_API_URL}/incidents?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      this.setState({
        incidents: incidents.data,
        isLoading: false
      });
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        this.setState({ error: errorMessage })
        setTimeout(() => {
          this.props.history.push("/");
          this.props.logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }

  render() {
    let incidentDataUrgence = [...this.state.incidentDataUrgence];
    let incidentDataStatus = [...this.state.incidentDataStatus];
    let incidentDataAddress = [];
    let incidentDataType = [
      { name: "Propreté", total: 0 },
      { name: "Sécurité", total: 0 },
      { name: "Incendie", total: 0 },
      { name: "Clima. et Chauf.", total: 0 },
      { name: "Wifi et Tel.", total: 0 },
      { name: "Ascenceurs", total: 0 },
      { name: "Courrier", total: 0 },
      { name: "Cafétérias", total: 0 },
      { name: "Plomb. et Elec.", total: 0 },
      { name: "Mobilier", total: 0 },
      { name: "Espaces verts", total: 0 },
      { name: "Bail", total: 0 },
      { name: "Déménagements", total: 0 },
      { name: "Autres", total: 0 },
    ];
    let averageCounter1 = 0;
    let averageCounter2 = 0;
    let averageCounter3 = 0;
    let averageCounter4 = 0;
    let averageLength1 = 0;
    let averageLength2 = 0;
    let averageLength3 = 0;
    let averageLength4 = 0;
    let addressList = [];

    this.state.incidents.forEach((incident) => {
      addressList.push(incident.addressName);
      incident.historicalStatus.forEach((savedStatus) => {
        switch (savedStatus.status) {
          case "Incident signalé":
            averageCounter1 += diffDaysFormat(savedStatus.timeOpened);
            averageLength1 += 1;
            break;
          case "Incident pris en charge":
            averageCounter2 += diffDaysFormat(savedStatus.timeOpened);
            averageLength2 += 1;
            break;
          case "Intervention planifiée":
            averageCounter3 += diffDaysFormat(savedStatus.timeOpened);
            averageLength3 += 1;
            break;
          case "Intervention réalisée":
            averageCounter4 += diffDaysFormat(savedStatus.timeOpened);
            averageLength4 += 1;
            break;

          default:
            break;
        }
      });
      switch (incident.urgence) {
        case "Très urgent":
          incidentDataUrgence[0].value += 1;
          break;
        case "Urgent":
          incidentDataUrgence[1].value += 1;
          break;
        case "Peu urgent":
          incidentDataUrgence[2].value += 1;
          break;
        case "Pas urgent":
          incidentDataUrgence[3].value += 1;
          break;
        case "Si possible":
          incidentDataUrgence[4].value += 1;
          break;

        default:
          break;
      }
      switch (incident.currentStatus[0].status) {
        case "Incident signalé":
          incidentDataStatus[0].incident += 1;
          break;
        case "Incident pris en charge":
          incidentDataStatus[1].incident += 1;
          break;
        case "Intervention planifiée":
          incidentDataStatus[2].incident += 1;
          break;
        case "Intervention réalisée":
          incidentDataStatus[3].incident += 1;
          break;
        case "Incident clos":
          incidentDataStatus[4].incident += 1;
          break;

        default:
          break;
      }
    });

    let uniqueAddressList = new Set(addressList);
    uniqueAddressList.forEach((address) => {
      incidentDataAddress.push({
        name: address,
        "Très urgent": 0,
        Urgent: 0,
        "Peu urgent": 0,
        "Pas urgent": 0,
        "Si possible": 0,
      });
    });

    incidentDataType.forEach((incident, i) => {
      uniqueAddressList.forEach((address) => {
        incidentDataType[i][address] = 0;
      });
    });

    this.state.incidents.forEach((incident) => {
      switch (incident.expertise) {
        case "Propreté":
          incidentDataType[0][incident.addressName] += 1;
          incidentDataType[0].total += 1;
          break;
        case "Sécurité":
          incidentDataType[1][incident.addressName] += 1;
          incidentDataType[1].total += 1;
          break;
        case "Détection et Extinction Incendie":
          incidentDataType[2][incident.addressName] += 1;
          incidentDataType[2].total += 1;
          break;
        case "Climatisation et Chauffage":
          incidentDataType[3][incident.addressName] += 1;
          incidentDataType[3].total += 1;
          break;
        case "Wifi et Téléphonie":
          incidentDataType[4][incident.addressName] += 1;
          incidentDataType[4].total += 1;
          break;
        case "Ascenseurs":
          incidentDataType[5][incident.addressName] += 1;
          incidentDataType[5].total += 1;
          break;
        case "Courrier":
          incidentDataType[6][incident.addressName] += 1;
          incidentDataType[6].total += 1;
          break;
        case "Cafétérias":
          incidentDataType[7][incident.addressName] += 1;
          incidentDataType[7].total += 1;
          break;
        case "Plomberie et Electricité":
          incidentDataType[8][incident.addressName] += 1;
          incidentDataType[8].total += 1;
          break;
        case "Mobilier, Décoration et Plantes":
          incidentDataType[9][incident.addressName] += 1;
          incidentDataType[9].total += 1;
          break;
        case "Espaces verts":
          incidentDataType[10][incident.addressName] += 1;
          incidentDataType[10].total += 1;
          break;
        case "Bail":
          incidentDataType[11][incident.addressName] += 1;
          incidentDataType[11].total += 1;
          break;
        case "Déménagements internes":
          incidentDataType[12][incident.addressName] += 1;
          incidentDataType[12].total += 1;
          break;
        case "Autres":
          incidentDataType[13][incident.addressName] += 1;
          incidentDataType[13].total += 1;
          break;

        default:
          break;
      }
    });

    averageCounter1 /= averageLength1;
    averageCounter2 /= averageLength2;
    averageCounter3 /= averageLength3;
    averageCounter4 /= averageLength4;

    let incidentDataUrgenceFiltered = incidentDataUrgence.filter(
      (entry) => entry.value > 0
    );

    incidentDataAddress.forEach((address, i) => {
      this.state.incidents.forEach((incident) => {
        if (address.name === incident.addressName) {
          switch (incident.urgence) {
            case "Très urgent":
              incidentDataAddress[i]["Très urgent"] += 1;
              break;
            case "Urgent":
              incidentDataAddress[i]["Urgent"] += 1;
              break;
            case "Peu urgent":
              incidentDataAddress[i]["Peu urgent"] += 1;
              break;
            case "Pas urgent":
              incidentDataAddress[i]["Pas urgent"] += 1;
              break;
            case "Si possible":
              incidentDataAddress[i]["Si possible"] += 1;
              break;

            default:
              break;
          }
        }
      });
    });

    return (
      <Wrapper>
        <NavMenu />
        <Title variant="h4">Statistiques Générales</Title>
        {this.state.isLoading ?
          <>
            <Grid container justifyContent="flex-end">
              {this.state.error && (
                <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                  {this.state.error}
                </em>
              )}
            </Grid>
            <Loader />
          </> :
          <Container maxWidth="xl">
            <Grid container justifyContent="center">
              <Title variant="h5">
                Incident{this.state.incidents.length > 1 ? "s" : ""} en cours (
                {this.state.incidents.length})
              </Title>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <SubTitle variant="h6">Par urgence :</SubTitle>
                <PieChart
                  width={
                    window.innerWidth >= 1280
                      ? (window.innerWidth - 200) / 2
                      : window.innerWidth - 200
                  }
                  height={300}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                >
                  <Pie
                    data={incidentDataUrgenceFiltered}
                    cx={250}
                    cy={130}
                    labelLine={false}
                    label
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {incidentDataUrgenceFiltered.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SubTitle variant="h6">Par statut :</SubTitle>
                <BarChart
                  width={
                    window.innerWidth >= 1280
                      ? (window.innerWidth - 200) / 2
                      : window.innerWidth - 200
                  }
                  height={300}
                  data={incidentDataStatus}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="incident" fill="#389C8A" />
                </BarChart>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SubTitle variant="h6">Par site :</SubTitle>
                <BarChart
                  width={
                    window.innerWidth >= 1280
                      ? (window.innerWidth - 200) / 2
                      : window.innerWidth - 200
                  }
                  height={300}
                  data={incidentDataAddress}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Très urgent" stackId="a" fill="#F44336" />
                  <Bar dataKey="Urgent" stackId="a" fill="#EF6C00" />
                  <Bar dataKey="Peu urgent" stackId="a" fill="#FBC02D" />
                  <Bar dataKey="Pas urgent" stackId="a" fill="#4CAF50" />
                  <Bar dataKey="Si possible" stackId="a" fill="#2196F3" />
                </BarChart>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SubTitle variant="h6">Par type et par site :</SubTitle>
                <BarChart
                  width={
                    window.innerWidth >= 1280
                      ? (window.innerWidth - 200) / 2
                      : window.innerWidth - 200
                  }
                  height={300}
                  data={incidentDataType.filter((x) => x.total > 0)}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {this.barStackTypeCreate(uniqueAddressList)}
                </BarChart>
              </Grid>

              <Grid item xs={12} lg={6}>
                {averageCounter1 > 1 && (
                  <SubTitle variant="h6">
                    Temps moyen de prise en charge :{" "}
                    {Math.floor(averageCounter1 / 24)} jour
                    {Math.floor(averageCounter1 / 24) > 1 ? "s" : ""}{" "}
                    {Math.floor(averageCounter1 % 24)} heure
                    {Math.floor(averageCounter1 % 24) > 1 ? "s" : ""}
                  </SubTitle>
                )}
                {averageCounter2 > 1 && (
                  <SubTitle variant="h6">
                    Temps moyen de planification :{" "}
                    {Math.floor(averageCounter2 / 24)} jour
                    {Math.floor(averageCounter2 / 24) > 1 ? "s" : ""}{" "}
                    {Math.floor(averageCounter2 % 24)} heure
                    {Math.floor(averageCounter2 % 24) > 1 ? "s" : ""}
                  </SubTitle>
                )}
                {averageCounter3 > 1 && (
                  <SubTitle variant="h6">
                    Temps moyen de réalisation :{" "}
                    {Math.floor(averageCounter3 / 24)} jour
                    {Math.floor(averageCounter3 / 24) > 1 ? "s" : ""}{" "}
                    {Math.floor(averageCounter3 % 24)} heure
                    {Math.floor(averageCounter3 % 24) > 1 ? "s" : ""}
                  </SubTitle>
                )}
                {averageCounter4 > 1 && (
                  <SubTitle variant="h6">
                    Temps moyen de cloture : {Math.floor(averageCounter4 / 24)}{" "}
                    jour
                    {Math.floor(averageCounter4 / 24) > 1 ? "s" : ""}{" "}
                    {Math.floor(averageCounter4 % 24)} heure
                    {Math.floor(averageCounter4 % 24) > 1 ? "s" : ""}
                  </SubTitle>
                )}
              </Grid>
            </Grid>
          </Container>
        }
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, null)(StatScreen);
