import React, { useEffect, useState } from "react";
import axios from "axios";
import { dateFormat } from "../helpers";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DialogContent from "@material-ui/core/DialogContent";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Nécessaire pour Filepond
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType
);

const ModifIncidentModal = ({
  open,
  close,
  incident,
  companies,
  status,
  readOnly,
  treat,
  currentStatus,
  historicalStatus,
  updateIncident,
  user
}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [dataFile, setDataFile] = useState(null);
  const [dateInter, setDateInter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    id: "",
    status: "",
    companyName: "",
    addressName: "",
    direction: "",
    building: "",
    floor: "",
    spaceType: "",
    officeName: "",
    incidentName: "",
    urgence: "",
    expertise: "",
    description: "",
    attachment: "",
    note: null,
    serviceProvider: "",
    comment: "",
    numOfIntervention: null,
    isLoading: false,
  });

  useEffect(() => {
    setState({
      id: incident._id,
      status: currentStatus.status,
      companyName: incident.companyName,
      addressName: incident.addressName,
      direction: incident.direction,
      building: incident.building,
      floor: incident.floor,
      spaceType: incident.spaceType,
      officeName: incident.officeName,
      incidentName: incident.incidentName,
      urgence: incident.urgence,
      expertise: incident.expertise,
      description: incident.description,
      attachment: incident.attachment,
      note: incident.note,
      serviceProvider: incident.serviceProvider,
      numOfIntervention: incident.numOfIntervention,
      comment: incident.comment,
    });
    setDateInter(currentStatus.dateInter);
    setError("");
  }, [
    currentStatus,
    incident._id,
    incident.direction,
    incident.companyName,
    incident.addressName,
    incident.building,
    incident.floor,
    incident.spaceType,
    incident.officeName,
    incident.incidentName,
    incident.urgence,
    incident.expertise,
    incident.description,
    incident.attachment,
    incident.note,
    incident.serviceProvider,
    incident.numOfIntervention,
    incident.comment,
  ]);

  const handleChange = (e) => {
    setError("");
    setSuccess("");
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleDate = (date) => {
    setError("");
    setSuccess("");
    setDateInter(date);
  };

  const onUpdateFiles = async (fileItems) => {
    const file = fileItems.map((fi) => fi.file)[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "fabworkplace");
    setDataFile(data);
  };

  const processForm = async () => {
    setIsLoading(true);
    const {
      id,
      status,
      building,
      floor,
      spaceType,
      officeName,
      incidentName,
      urgence,
      expertise,
      description,
      note,
      serviceProvider,
      comment,
      direction,
      numOfIntervention } = state;
    let newDirection = direction === "" ? "Services généraux" : direction;
    try {
      if (numOfIntervention < 1 && status === "Incident clos") {
        throw new Error("Remplissez le nombre d'intervention");
      }
      const processedIncident = await axios.patch(
        `${process.env.REACT_APP_API_URL}/incidents/process/${id}`,
        {
          building,
          floor,
          spaceType,
          officeName,
          incidentName,
          urgence,
          expertise,
          description,
          direction: newDirection,
          status,
          dateInter,
          note,
          serviceProvider,
          numOfIntervention,
          comment,
          user
        }
      );
      updateIncident(processedIncident.data);
      setSuccess("L'incident a bien été traité");
      setTimeout(() => {
        close();
        setSuccess("");
      }, 1500);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  const updateForm = async () => {
    setIsLoading(true);
    const {
      id,
      direction,
      building,
      floor,
      spaceType,
      officeName,
      incidentName,
      urgence,
      expertise,
      description,
      attachment,
      note,
      serviceProvider,
      comment,
    } = state;
    let newDirection = direction === "" ? "Services généraux" : direction;

    try {
      if (
        !state.urgence ||
        !state.incidentName ||
        !state.expertise ||
        !state.description
      ) {
        throw new Error("Remplissez les champs avec * correctement");
      }
      if (dataFile !== null) {
        axios
          .post(process.env.REACT_APP_CLOUDINARY_URL, dataFile)
          .then(async (res) => {
            const updatedIncident = await axios.patch(
              `${process.env.REACT_APP_API_URL}/incidents/update/${id}`,
              {
                direction: newDirection,
                building,
                floor,
                spaceType,
                officeName,
                incidentName,
                urgence,
                expertise,
                description,
                attachment: res.data.secure_url,
                note,
                serviceProvider,
                comment,
                user
              }
            );
            setSuccess("Votre incident a bien été modifié");
            updateIncident(updatedIncident.data);
            setTimeout(() => {
              close();
              setSuccess("");
              setError("");
              setDataFile(null);
            }, 1500);
          });
      } else {
        const updatedIncident = await axios.patch(
          `${process.env.REACT_APP_API_URL}/incidents/update/${id}`,
          {
            direction: newDirection,
            building,
            floor,
            spaceType,
            officeName,
            incidentName,
            urgence,
            expertise,
            description,
            attachment,
            note,
            serviceProvider,
            comment,
            user
          }
        );
        setSuccess("Votre incident a bien été modifié");
        updateIncident(updatedIncident.data);
        setTimeout(() => {
          close();
          setSuccess("");
          setError("");
          setDataFile(null);
        }, 1500);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  ;
  let selectedCompany = companies.filter(el => el._id === incident.companyId)
  let directionList = selectedCompany.length !== 1 ? (
    <option
      key={"1"}
      value=""
    >Erreur de chargement</option>
  ) : selectedCompany[0].directions.map((direction) => {
    return (
      <option
        key={direction}
        value={direction}
      >{`${direction}`}</option>
    );
  });



  return (
    <div>
      <Dialog
        disableBackdropClick={!readOnly && treat}
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
        style={{ padding: "20px" }}
      >
        <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
          {!readOnly ? treat ? "Traiter Incident" : "Modifier Incident" : state.incidentName}
        </DialogTitle>
        {error && <em style={{ color: "red", margin: "0 auto" }}>{error}</em>}
        {success && (
          <em style={{ color: "green", margin: "0 auto" }}>{success}</em>
        )}
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={10} sm={11}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Status</InputLabel>
                <Select
                  disabled={!treat}
                  label="Status"
                  native
                  value={state.status}
                  onChange={handleChange}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <option value="Incident signalé">Incident signalé</option>
                  <option value="Incident pris en charge">
                    Incident pris en charge
                  </option>
                  <option value="Intervention planifiée">
                    Intervention planifiée
                  </option>
                  <option value="Intervention réalisée">
                    Intervention réalisée
                  </option>
                  <option value="Incident clos">Incident clos</option>
                </Select>
              </FormControl>
            </Grid>
            {!treat && state.status === "Intervention planifiée" && (
              <DialogContentText>
                Le : {dateFormat(incident.currentStatus[0].dateInter)}
              </DialogContentText>
            )}

            {treat && state.status === "Incident clos" && (
              <Grid item xs={10} sm={11}>
                <FormControl
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <TextField
                    label="Nombre d'intervention"
                    variant="outlined"
                    id="numOfIntervention"
                    onChange={handleChange}
                    type="text"
                    name="numOfIntervention"
                    value={state.numOfIntervention}
                  />
                </FormControl>
              </Grid>
            )}

          </Grid>

          {treat && state.status === "Intervention planifiée" && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid item xs={10} sm={11}>
                    <FormControl
                      fullWidth
                      style={{ margin: "1rem auto" }}>
                      <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        format="dd/MM/yyyy"
                        label="Date d'intervention"
                        value={dateInter}
                        onChange={handleDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid container justify="space-around">
                <Grid item xs={10} sm={11}>
                  <FormControl
                    fullWidth
                    style={{ margin: "1rem auto" }}>
                    <TextField
                      variant="outlined"
                      id="serviceProvider"
                      type="text"
                      name="serviceProvider"
                      label="Fournisseur de service"
                      onChange={handleChange}
                      value={state.serviceProvider}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          {/* {status === 1 && (
            <TextField
              disabled={readOnly && !treat}
              name="historicalStatus"
              id="historicalStatus"
              label="Historique"
              multiline
              rowsMax="4"
              fullWidth
              value={formatHistoricalStatus(historicalStatus)}
              variant="outlined"
              margin="normal"
            />
          )} */}
          <Grid container justify="space-around">
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={true}
                  id="companyName"
                  type="text"
                  name="companyName"
                  label="Companie"
                  value={state.companyName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={true}
                  id="addressName"
                  type="text"
                  name="addressName"
                  label="Lieu"
                  value={state.addressName}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justify="space-around">
            <Grid item xs={10} sm={11}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Direction</InputLabel>
                <Select
                  disabled={directionList.length <= 1 || readOnly}
                  label="Direction"
                  native
                  value={state.direction}
                  onChange={handleChange}
                  inputProps={{
                    name: "direction",
                    id: "direction",
                  }}
                >
                  {directionList}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  id="building"
                  type="text"
                  name="building"
                  label="Batiment"
                  onChange={handleChange}
                  value={state.building}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  id="floor"
                  type="text"
                  name="floor"
                  label="Etage"
                  onChange={handleChange}
                  value={state.floor}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Type d'espace</InputLabel>
                <Select
                  label="Type d'espace"
                  native
                  disabled={readOnly}
                  value={state.spaceType}
                  onChange={handleChange}
                  inputProps={{
                    name: "spaceType",
                    id: "spaceType",
                  }}
                >
                  <option value="">Type d'espace</option>
                  <option value="Openspace">Openspace</option>
                  <option value="Bureau">Bureau</option>
                  <option value="Salle de réunion">Salle de réunion</option>
                  <option value="Cafétéria">Cafétéria</option>
                  <option value="Sanitaires">Sanitaires</option>
                  <option value="Espace extérieur">Espace extérieur</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  id="officeName"
                  type="text"
                  name="officeName"
                  label="Nom de l'espace"
                  onChange={handleChange}
                  value={state.officeName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={11}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">
                  Urgence
                </InputLabel>
                <Select
                  disabled={readOnly}
                  label="Urgence"
                  native
                  value={state.urgence}
                  onChange={handleChange}
                  inputProps={{
                    name: "urgence",
                    id: "urgence",
                  }}
                >
                  <option value="Très urgent">Très urgent</option>
                  <option value="Urgent">Urgent</option>
                  <option value="Peu urgent">Peu urgent</option>
                  <option value="Pas urgent">Pas urgent</option>
                  <option value="Si possible">Si possible</option>
                </Select>
              </FormControl>
            </Grid>


          </Grid>

          <Grid container justify="space-around">
            <Grid item xs={10} sm={11}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  disabled={readOnly}
                  id="incidentName"
                  type="text"
                  name="incidentName"
                  label="5 mots pour le décrire"
                  onChange={handleChange}
                  value={state.incidentName}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justify="space-around">



            <Grid item xs={10} sm={11}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Expertise</InputLabel>
                <Select
                  label="Expertise"
                  disabled={readOnly}
                  native
                  value={state.expertise}
                  onChange={handleChange}
                  inputProps={{
                    name: "expertise",
                    id: "expertise",
                  }}
                >
                  <option value="">Expertise</option>
                  <option value="Propreté">Propreté</option>
                  <option value="Sécurité">Sécurité</option>
                  <option value="Détection et Extinction Incendie">Détection et Extinction Incendie</option>
                  <option value="Climatisation et Chauffage">
                    Climatisation et Chauffage
                  </option>
                  <option value="Wifi et Téléphonie">Wifi et Téléphonie</option>
                  <option value="Ascenseurs">Ascenseurs</option>
                  <option value="Courrier">Courrier</option>
                  <option value="Cafétérias">Cafétérias</option>
                  <option value="Plomberie et Electricité">
                    Plomberie, Electricité, Serrurerie, Menuiserie
                  </option>
                  <option value="Mobilier, Décoration et Plantes">
                    Mobilier, Décoration et Plantes
                  </option>
                  <option value="Espaces verts">Espaces verts</option>
                  <option value="Bail">Bail</option>
                  <option value="Déménagements internes">
                    Aménagements et Déménagements
                  </option>
                  <option value="Autres">Autres</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justify="space-around">
            <Grid item xs={10} sm={11}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  disabled={readOnly}
                  name="description"
                  id="description"
                  label="Description"
                  multiline
                  rowsMax="4"
                  value={state.description}
                  onChange={handleChange}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            {(status === 1 || status === 5 || status === 6) && (
              <Grid item xs={10} sm={11}>
                <FormControl
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <TextField
                    disabled={readOnly && !treat}
                    name="comment"
                    id="comment"
                    label="Commentaire"
                    multiline
                    rowsMax="4"
                    value={state.comment}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          {state.attachment && (
            <Grid container justify="center">
              <img
                style={{
                  borderRadius: "15px",
                  width: "50%",
                  height: "auto",
                }}
                src={state.attachment}
                alt=""
              />
            </Grid>
          )}
          {(!readOnly && !treat) && (
            <>
              <br />
              <FilePond
                name="uploadPhoto"
                maxFiles={1}
                onupdatefiles={onUpdateFiles}
                allowFileTypeValidation
                acceptedFileTypes={["image/png", "image/jpeg"]}
                filepond-plugin-file-validate-type="Format image invalide!"
                labelIdle='Glisser-déposer votre photo ou <span class="filepond--label-action">Parcourir</span>'
              />
            </>
          )}
          <DialogActions>
            <Button variant="outlined" onClick={close} color="primary">
              FERMER
            </Button>
            {(!readOnly && !treat) && (
              <Button
                disabled={isLoading}
                variant="outlined"
                onClick={updateForm}
                color="primary"
              >
                VALIDER
              </Button>
            )}
            {treat && (
              <Button
                disabled={isLoading}
                variant="outlined"
                onClick={processForm}
                color="primary"
              >
                VALIDER
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModifIncidentModal;
