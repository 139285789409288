/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import Slide from "@material-ui/core/Slide";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModifCompanyModal = ({ open, close, company, wppList, updateClient, user }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [alert, setAlert] = useState("");
  const [wppIds, setWppIds] = useState([]);
  const [state, setState] = useState({
    sendEmail: false,
    emailList: "",
    commentContract: "",
    nameCompany: "",
    numContract: "",
    numOfEmployeesTotal: "",
    directions: [],
    newDirection: ""
  });

  useEffect(() => setError(""), [close]);

  useEffect(() => {
    setStartDate(company.dateStartContract);
    setEndDate(company.dateEndContract);
    if (!company.wppIds || company.wppIds.length < 1) {
      setWppIds([company.wpp, company.wppBackupId])
    } else {
      setWppIds(company.wppIds)
    }
    setState({
      sendEmail: company.sendEmail,
      emailList: company.emailList,
      commentContract: company.commentContract,
      nameCompany: company.nameCompany,
      numContract: company.numContract,
      numOfEmployeesTotal: company.numOfEmployeesTotal,
      directions: company.directions
    });
  }, [company.dateEndContract, company.dateStartContract, company]);

  const handleDelete = (element) => {
    let directions = state.directions.filter(el => el !== element)
    setState({ ...state, directions })
    setAlert('Valider le formulaire pour enregistrer les modifications')
  }

  const handleStartChange = (date) => {
    setStartDate(date);
  };

  const handleEndChange = (date) => {
    setEndDate(date);
  };

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const handleChangeWppIds = (event) => {
    const {
      target: { value },
    } = event;
    setWppIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const addDirection = () => {
    if (state.newDirection) {
      let directions = state.directions
      directions.push(state.newDirection)
      setState({ ...state, directions, newDirection: "" })
      setAlert('Valider le formulaire pour enregistrer les modifications')
    } else {
      setError("Le champs d'ajout est vide");
    }
  }

  const submitForm = async () => {
    setAlert('')
    const { nameCompany, numContract, numOfEmployeesTotal } = state;

    try {
      if (!nameCompany || !numContract || !numOfEmployeesTotal || wppIds.length < 1) {
        throw new Error("Veuillez remplir tous les champs!");
      }

      setLoading(true);
      const updatedCompany = await Axios.patch(
        `${process.env.REACT_APP_API_URL}/companies/update/${company._id}`,
        {
          ...state,
          wppIds,
          dateStartContract: startDate,
          dateEndContract: endDate,
          user
        }
      );
      updateClient(updatedCompany.data);

      setSuccess("Compagnie mise à jour!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
      }, 1500);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  let directionList = []
  if (state.directions) {
    directionList = state.directions.map(el => {
      return <Chip style={{ margin: "1rem 0.5rem" }} key={el} label={el} onDelete={() => handleDelete(el)} color="primary" variant="outlined" />
    })
  }

  const wppListOption = wppList.map((wpp, i) => {
    const name = (wpp.firstname && wpp.lastname) ? `${wpp.firstname} ${wpp.lastname}` : wpp.email;
    return (
      <MenuItem key={wpp._id} value={wpp._id}>
        {name}
      </MenuItem>
    );
  });

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Modifier Compagnie
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={state.sendEmail}
              onChange={handleCheck}
              name="sendEmail"
              color="primary"
            />
          }
          label="Envoyer un mail pour la création d'incident"
        />
        <TextField
          disabled={!state.sendEmail}
          onChange={handleChange}
          name="emailList"
          value={state.emailList}
          type="text"
          label="Emails de contact"
          margin="normal"
          placeholder="adresse1@mail.com, adresse2@mail.com..."
          fullWidth
          variant="filled"
        />
        <TextField
          onChange={handleChange}
          value={state.nameCompany}
          name="nameCompany"
          type="text"
          label="Company"
          style={{ margin: "1rem auto 3rem auto" }}
          fullWidth
          variant="filled"
        />
        <Grid container justifyContent="space-around">
          <FormControl style={{ width: "90%", marginBottom: "20px" }}>
            <InputLabel htmlFor="age-native-simple">WPP en charge *</InputLabel>
            <Select
              multiple
              value={wppIds}
              onChange={handleChangeWppIds}
              inputProps={{
                name: "wppId",
                id: "wppId"
              }}
            >
              {wppListOption}
            </Select>
          </FormControl>
        </Grid>
        <DialogContentText color="primary">
          Gestion des Directions
        </DialogContentText>
        <Grid container justifyContent="space-between">
          <TextField
            onChange={handleChange}
            value={state.newDirection}
            name="newDirection"
            type="text"
            label="Ajouter une direction"
            style={{ margin: "1rem auto 1rem auto" }}
            fullWidth
            variant="filled"
          />
          <Button variant="outlined" onClick={addDirection} color="primary">
            Ajouter
          </Button>
          {alert && (
            <em style={{ fontWeight: "bold", color: "orange", margin: "0 auto" }}>
              {alert}
            </em>
          )}
        </Grid>
        <Grid container justifyContent="space-around">
          {directionList}
        </Grid>
        <DialogContentText color="primary">
          Informations Contrat
        </DialogContentText>
        <TextField
          onChange={handleChange}
          value={state.numContract}
          name="numContract"
          type="text"
          label="Numéro de contrat"
          style={{ margin: "8 auto" }}
          placeholder="numéro de contrat"
          fullWidth
          margin="normal"
          variant="filled"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Signature de contrat"
              margin="normal"
              value={startDate}
              onChange={handleStartChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Echéance du contrat"
              margin="normal"
              value={endDate}
              onChange={handleEndChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <TextField
          onChange={handleChange}
          value={state.numOfEmployeesTotal}
          name="numOfEmployeesTotal"
          label="Nombre total de salariés"
          type="number"
          style={{ margin: "8 auto" }}
          fullWidth
          margin="normal"
          variant="filled"
        />
        <TextField
          label="Commentaires"
          multiline
          maxRows="4"
          fullWidth
          name="commentContract"
          value={state.commentContract}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button variant="outlined" onClick={submitForm} color="primary">
          {loading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifCompanyModal;
