import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../actions";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import AddUserModal from "./AddUserModal";
import AddUserListModal from "./AddUserListModal";
import DeleteUserModal from "./DeleteUserModal";
import ModifUserModal from "./ModifUserModal";
import UserCard from "./UserCard";
import NavMenu from "./Navs/NavMenu";
import Loader from "./Loader";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 7rem;
  padding: 1rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
`;

const UserList = ({ history, location, user, logout }) => {
  const [addModal, setAddModal] = useState(false);
  const [addListModal, setAddListModal] = useState(false);
  const [modifModal, setModifModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userClicked, setUserClicked] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { company, address } = location.state;
  useEffect(() => {
    try {
      const fetchData = async () => {
        const allUsers = await axios.get(
          `${process.env.REACT_APP_API_URL}/users?id=${user.id}&token=${user.token}`
        );
        setUsers(allUsers.data);
        setIsLoading(false);
      }
      fetchData()
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        setError(errorMessage)
        setTimeout(() => {
          history.push("/");
          logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }, [history, logout, user.id, user.token])

  const modifUserModal = (user) => {
    setUserClicked(user);
    setModifModal(true);
  };

  const deleteUserModal = (user) => {
    setUserClicked(user);
    setDeleteModal(true);
  };

  const addUser = user => {
    setUsers([...users, user])
  }

  const addUserList = userList => {
    setUsers([...users, ...userList])
  }

  const updateUser = user => {
    let userIds = users.map(u => u._id)
    let index = userIds.indexOf(user._id)
    let add = [...users]
    add[index] = user
    setUsers(add)
  }

  const deleteUser = user => {
    let updatedUsers = [...users].filter(u => u._id !== user._id)
    setUsers(updatedUsers)
  }

  // company existe uniquement pour les wpp qui peuvent gérer plusieurs companies 
  // sinon c'est le user companyId qui est selectionné pour afficher les addresses et en ajouter
  const companyId = company?._id || user.companyId
  const addressId = address?._id || user.addressId
  const addressUsers = users.filter(
    (user) => user.addressId === addressId
  );

  const usersCard = addressUsers.map((user) => {
    return (
      <UserCard
        key={user._id}
        user={user}
        modifUser={modifUserModal}
        deleteUser={deleteUserModal}
      />
    );
  });

  return (
    <Wrapper>
      <NavMenu />
      {isLoading ?
        <>
          <Grid container justifyContent="flex-end">
            {error && (
              <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                {error}
              </em>
            )}
          </Grid>
          <Loader />
        </> :
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Title variant="h4">
                Utilisateurs
                {user.status === 1
                  ? ` de ${company.nameCompany} (${address.nameAddress})`
                  : ` (${address.nameAddress})`}
              </Title>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="flex-end">
                <Button
                  onClick={() =>
                    history.push({
                      pathname: "/addressList",
                      state: { company },
                    })
                  }
                  variant="contained"
                  size="small"
                  startIcon={<ArrowBackIcon />}
                  style={{
                    background: "#1C3144",
                    color: "#FFF",
                    outline: "none",
                    margin: "0.5rem",
                  }}
                >
                  Retour
                </Button>
                <Button
                  endIcon={<GroupAddIcon />}
                  onClick={() => {
                    setAddListModal(true);
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1C3144",
                    color: "#FFF",
                    outline: "none",
                    margin: "0.5rem",
                  }}
                >
                  liste d'utilisateurs
                </Button>
                <Button
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => {
                    setAddModal(true);
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    background: "#1C3144",
                    color: "#FFF",
                    outline: "none",
                    margin: "0.5rem",
                  }}
                >
                  Nouvel Utilisateur
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            {usersCard}
          </Grid>
        </Container>
      }
      <AddUserModal
        addUser={addUser}
        user={user}
        companyId={companyId}
        addressId={addressId}
        open={addModal}
        close={() => setAddModal(false)}
      />
      <AddUserListModal
        addUserList={addUserList}
        user={user}
        companyId={companyId}
        addressId={addressId}
        open={addListModal}
        close={() => setAddListModal(false)}
      />
      <ModifUserModal
        updateUser={updateUser}
        open={modifModal}
        close={() => setModifModal(false)}
        userClicked={userClicked}
        user={user}
      />
      <DeleteUserModal
        deleteUser={deleteUser}
        open={deleteModal}
        close={() => setDeleteModal(false)}
        userClicked={userClicked}
        user={user}
      />
    </Wrapper>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));
