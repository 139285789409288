import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../actions";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ModifCompanyModal from "./ModifCompanyModal";
import ClientCard from "./ClientCard";
import AddCompanyModal from "./AddCompanyModal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Loader from "./Loader";
import NavMenu from "./Navs/NavMenu";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 7rem;
  padding: 1rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
`;

const ClientList = ({
  user,
  history,
  wppList,
  logout
}) => {
  const [company, setCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modifModal, setModifModal] = useState(false);
  const [newCompModal, setNewCompModal] = useState(false);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const companies = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies?id=${user.id}&token=${user.token}`
        );
        setCompanies(companies.data);
        setIsLoading(false);
      }
      fetchData()
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        setError(error?.response)
        setTimeout(() => {
          history.push("/");
          logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }, [history, logout, user.id, user.token])


  const clientClick = (company) => {
    setCompany(company);
    setModifModal(true);
  };

  const goToAddresses = (company) => {
    setCompany(company);
    history.push({
      pathname: "/addressList",
      state: { company },
    });
  };

  const addClient = client => {
    setCompanies([...companies, client])
  }

  const updateClient = client => {
    let companieIds = companies.map(c => c._id)
    let index = companieIds.indexOf(client._id)
    let comp = [...companies]
    comp[index] = client
    setCompanies(comp)
  }

  const clients = companies.map((company) => {
    return (
      <ClientCard
        key={company._id}
        company={company}
        clientClick={clientClick}
        goToAddresses={goToAddresses}
      />
    );
  });

  return (
    <Wrapper>
      <NavMenu />
      {isLoading ?
        <>
          <Grid container justifyContent="flex-end">
            {error && (
              <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                {error}
              </em>
            )}
          </Grid>
          <Loader />
        </> :
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Title variant="h4">Mes Clients</Title>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="flex-end">
                <Button
                  size="small"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={() => setNewCompModal(true)}
                  variant="contained"
                  style={{
                    background: "#1C3144",
                    color: "#FFF",
                    outline: "none",
                    margin: "0.5rem",
                  }}
                >
                  Nouveau Client
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            {error && (
              <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                {error}
              </em>
            )}
          </Grid>
          <Grid container justifyContent="space-around">
            {clients}
          </Grid>
        </Container>
      }
      <ModifCompanyModal
        user={user}
        updateClient={updateClient}
        wppList={wppList}
        open={modifModal}
        close={() => setModifModal(false)}
        company={company}
      />
      <AddCompanyModal
        user={user}
        addClient={addClient}
        wppList={wppList}
        open={newCompModal}
        close={() => setNewCompModal(false)}
        company={company}
      />
    </Wrapper>
  );
};

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default withRouter(connect(null, mapDispatchToProps)(ClientList));
