/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Slide from "@material-ui/core/Slide";
import { Select } from "@material-ui/core";
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserModal = ({ open, close, companyId, addressId, addUser, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    email: "",
    status: 4,
  });

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const submitForm = async () => {
    const { email } = state;

    try {
      if (!email) {
        throw new Error("Veuillez remplir tous les champs!");
      }
      setIsLoading(true);
      const addedUser = await Axios.post(
        `${process.env.REACT_APP_API_URL}/users/add`,
        {
          ...state,
          companyId,
          addressId,
          user
        }
      );

      addUser(addedUser.data);
      setSuccess("Utilisateur ajoutée avec succès!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
      }, 1500);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const wppOptions = (
    <>
      <option value={1}>WorkPlace Partner</option>
      <option value={2}>Company Officer</option>
      <option value={3}>Company Manager</option>
      <option value={4}>Utilisateur</option>
      <option value={5}>Company Officer Autonome</option>
      <option value={6}>Company Manager Autonome</option>
    </>
  );

  const officerOptions = (
    <>
      <option value={3}>Company Manager</option>
      <option value={4}>Utilisateur</option>
    </>
  );

  const officerAutonomeOptions = (
    <>
      <option value={6}>Company Manager</option>
      <option value={4}>Utilisateur</option>
    </>
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Ajouter Utilisateur
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <Grid container justifyContent="space-around">
          <Grid item xs={10} sm={11}>
            <FormControl
              fullWidth
              style={{ margin: "1rem auto" }}>
              <TextField
                required
                onChange={handleChange}
                value={state.numContract}
                name="email"
                type="email"
                label="Adresse Email"
                placeholder="email"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          {(user.status === 1 || user.status === 2 || user.status === 5) && (
            <Grid item xs={10} sm={11}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Choix du statut</InputLabel>
                <Select
                  native
                  label="Choix du statut"
                  value={state.status}
                  onChange={handleChange}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  {user.status === 1
                    ? wppOptions
                    : user.status === 2
                      ? officerOptions
                      : officerAutonomeOptions}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button variant="outlined" onClick={submitForm} color="primary">
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
