import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import IncidentScreen from "./components/screens/IncidentScreen";
import ClientListScreen from "./components/screens/ClientListScreen";
import UserRegistrationScreen from "./components/screens/UserRegistrationScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import HomeScreen from "./components/screens/HomeScreen";
import NewsScreen from "./components/screens/NewsScreen";
import StatScreen from "./components/screens/StatScreen";
import AddressList from "./components/AddressList";
import MessageScreen from "./components/screens/MessageScreen";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserList from "./components/UserList";
import dotenv from "dotenv";
import HttpsRedirect from "react-https-redirect";

dotenv.config();

// Compose Enhancers
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});


//persist Config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  version: 1,
};

//Thème global
const theme = createTheme({
  typography: {
    fontWeight: 900,
    fontFamily:
      " nunito,source-code-pro, Menlo, Monaco, Consolas, CourierNew, monospace, Arial",
  },
});


//persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

const persistor = persistStore(store);

export default class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HttpsRedirect>
              {/* Le composant <Router> </Router> st l’élément principale de React Router. On doit utiliser un composant <Route> partout où le rendu est basé sur l’url du navigateur. */}
              <Router>
                {/* Le composant <Route> attend un paramètre path qui définit à quel chemin (partie droite de quelle url, après le nom de domaine) cette route est associée.  */}
                {/* Une fois la <Route> activée grâce à se paramètre path la route va activer le rendu d’un composant. */}
                <div>
                  <Route exact path="/" component={HomeScreen} />
                  <Route path="/newsScreen" component={NewsScreen} />
                  <Route path="/statScreen" component={StatScreen} />
                  <Route path="/incidentScreen" component={IncidentScreen} />
                  <Route path="/messageScreen" component={MessageScreen} />
                  <Route
                    path="/clientListScreen"
                    component={ClientListScreen}
                  />
                  <Route path="/addressList" component={AddressList} />
                  <Route
                    path="/resetPasswordScreen"
                    component={ResetPasswordScreen}
                  />
                  <Route path="/userList" component={UserList} />
                  <Route
                    path="/userRegistrationScreen"
                    component={UserRegistrationScreen}
                  />
                </div>
              </Router>
            </HttpsRedirect>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    );
  }
}
