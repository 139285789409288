import React from "react";
import axios from "axios";

import { Container } from "reactstrap";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { formatAddress } from "../../helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import NavMenu from "../Navs/NavMenu";

// Nécessaire pour Filepond
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType
);

const Wrapper = styled.div`
  margin: 6.8rem auto auto 5.5rem;
  background-color: #f9f0f1;
  font-family: nunito;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
  padding: 1.5rem;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

class IncidentScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      direction: "",
      companyId: "",
      addressId: "",
      building: "",
      floor: "",
      spaceType: "",
      officeName: "",
      incidentName: "",
      urgence: "",
      expertise: "",
      description: "",
      attachment: "",
      note: "",
      serviceProvider: "",
      comment: "",
      error: "",
      success: "",
      isLoading: false,
      data: null,
      addresses: [],
      companies: []
    };
  }

  // Charge tous les incidents dans le state pour pouvoir les filtrer
  async componentDidMount() {
    try {
      const addresses = await axios.get(
        `${process.env.REACT_APP_API_URL}/address?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const companies = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      this.setState({
        addresses: addresses.data,
        companies: companies.data,
        isLoading: false
      });
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        this.setState({ error: errorMessage })
        setTimeout(() => {
          this.props.history.push("/");
          this.props.logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value, error: "", success: "" });
  };

  onUpdateFiles = async (fileItems) => {
    const file = fileItems.map((fi) => fi.file)[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "fabworkplace");
    this.setState({ data });
  };

  submitForm = async () => {
    this.setState({ isLoading: true });
    let companyName;
    let addressName;
    let companyId = this.state.companyId === "" ? this.props.user.companyId : this.state.companyId;
    let addressId = this.state.addressId === "" ? this.props.user.addressId : this.state.addressId;
    let direction = this.state.direction === "" ? "Services généraux" : this.state.direction;
    this.state.companies.forEach((company) => {
      if (company._id === companyId) {
        companyName = company.nameCompany;
      }
    });
    this.state.addresses.forEach((address) => {
      if (address._id === addressId) {
        addressName = address.nameAddress;
      }
    });
    const {
      building,
      floor,
      spaceType,
      officeName,
      incidentName,
      urgence,
      expertise,
      description,
      currentStatus,
      note,
      serviceProvider,
      comment,
    } = this.state;
    try {
      if (
        !this.state.urgence ||
        !this.state.incidentName ||
        !this.state.expertise ||
        !this.state.description
      ) {
        throw new Error("Remplissez les champs avec * correctement");
      }
      if (this.state.data !== null) {
        axios
          .post(process.env.REACT_APP_CLOUDINARY_URL, this.state.data)
          .then((res) => {
            axios
              .post(`${process.env.REACT_APP_API_URL}/incidents/add`, {
                companyId,
                userId: this.props.user.id,
                addressId,
                addressName,
                companyName,
                direction,
                building,
                floor,
                spaceType,
                officeName,
                incidentName,
                urgence,
                expertise,
                description,
                attachment: res.data.secure_url,
                currentStatus,
                note,
                serviceProvider,
                comment,
                user: this.props.user
              })
              .then((incident) => {
                this.setState({
                  isLoading: false,
                  success: "Votre incident a bien été envoyé",
                });
                setTimeout(() => {
                  this.setState({
                    companyId: "",
                    addressId: "",
                    direction: "",
                    building: "",
                    floor: "",
                    spaceType: "",
                    officeName: "",
                    incidentName: "",
                    urgence: "",
                    expertise: "",
                    description: "",
                    attachment: "",
                    note: "",
                    serviceProvider: "",
                    comment: "",
                    success: "",
                    data: null,
                  });
                  this.props.history.push("/newsScreen");
                }, 1500);
              });
          });
      } else {
        axios
          .post(`${process.env.REACT_APP_API_URL}/incidents/add`, {
            companyId,
            userId: this.props.user.id,
            addressId,
            addressName,
            companyName,
            building,
            direction,
            floor,
            spaceType,
            officeName,
            incidentName,
            urgence,
            expertise,
            description,
            currentStatus,
            note,
            serviceProvider,
            comment,
            user: this.props.user
          })
          .then((incident) => {
            this.setState({
              isLoading: false,
              success: "Votre incident a bien été envoyé",
            });
            setTimeout(() => {
              this.setState({
                companyId: "",
                addressId: "",
                direction: "",
                building: "",
                floor: "",
                spaceType: "",
                officeName: "",
                incidentName: "",
                urgence: "",
                expertise: "",
                description: "",
                attachment: "",
                note: "",
                serviceProvider: "",
                comment: "",
                success: "",
                data: null,
              });
              this.props.history.push("/newsScreen");
            }, 1500);
          });
      }
    } catch (error) {
      this.setState({
        error: error.message,
        isLoading: false,
      });
    }
  };
  render() {
    const clientList = this.state.companies.map((company) => {
      return (
        <option
          key={company._id}
          value={company._id}
        >{`${company.nameCompany}`}</option>
      );
    });
    let directionList = [];
    if (this.state.companyId === "") {
      directionList = this.state.companies.length > 0 ? this.state.companies[0]?.directions.map((direction) => {
        return (
          <option
            key={direction}
            value={direction}
          >{`${direction}`}</option>
        );
      }) : [];
    } else {
      let selectedCompany = this.state.companies.filter(el => el._id === this.state.companyId)
      directionList = selectedCompany[0]?.directions.map((direction) => {
        return (
          <option
            key={direction}
            value={direction}
          >{`${direction}`}</option>
        );
      });
    }

    const addressListWpp = this.state.addresses.map((address) => {
      if (this.state.companyId === address.companyId) {
        return (
          <option key={address._id} value={address._id}>
            {formatAddress(
              address.nameAddress,
              address.street,
              address.zip,
              address.city
            )}
          </option>
        );
      } else {
        return 1;
      }
    });

    const addressListOfficer = this.state.addresses.map((address) => {
      if (this.props.user.companyId === address.companyId) {
        return (
          <option key={address._id} value={address._id}>
            {formatAddress(
              address.nameAddress,
              address.street,
              address.zip,
              address.city
            )}
          </option>
        );
      } else {
        return 1;
      }
    });

    return (
      <Wrapper>
        <NavMenu />
        <Title variant="h4">Déclaration d'incident</Title>
        <Container>
          {this.props.user.status === 1 && (
            <Grid container justifyContent="space-around">
              <Grid item xs={10} sm={5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <InputLabel htmlFor="age-native-simple">Client</InputLabel>
                  <Select
                    label="Client"
                    native
                    value={this.state.companyId}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "companyId",
                      id: "companyId",
                    }}
                  >
                    <option value="" />
                    {clientList}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <InputLabel htmlFor="age-native-simple">Adresse</InputLabel>
                  <Select
                    label="Adresse"
                    disabled={this.state.companyId === ""}
                    native
                    value={this.state.addressId}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "addressId",
                      id: "addressId",
                    }}
                  >
                    <option value="" />
                    {addressListWpp}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {(this.props.user.status === 2 || this.props.user.status === 5) && (
            <Grid container justifyContent="space-around">
              <Grid item xs={10} sm={5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <InputLabel htmlFor="age-native-simple">
                    Adresse
                  </InputLabel>
                  <Select
                    label="Adresse"
                    native
                    value={this.state.addressId}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "addressId",
                      id: "addressId",
                    }}
                  >
                    <option value="" />
                    {addressListOfficer}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          )}
          <Grid container justifyContent="space-around">
            <Grid item xs={10} sm={5}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Direction</InputLabel>
                <Select
                  label="Direction"
                  disabled={directionList.length <= 1}
                  native
                  value={this.state.direction}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "direction",
                    id: "direction",
                  }}
                >
                  <option value="" />
                  {directionList}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  id="building"
                  type="text"
                  name="building"
                  label="Batiment"
                  onChange={this.handleChange}
                  value={this.state.building}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  id="floor"
                  type="text"
                  name="floor"
                  label="Etage"
                  onChange={this.handleChange}
                  value={this.state.floor}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={10} sm={5}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">Type d'espace</InputLabel>
                <Select
                  label="Type d'espace"
                  native
                  value={this.state.spaceType}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "spaceType",
                    id: "spaceType",
                  }}
                >
                  <option value="" />
                  <option value="Openspace">Openspace</option>
                  <option value="Bureau">Bureau</option>
                  <option value="Salle de réunion">Salle de réunion</option>
                  <option value="Cafétéria">Cafétéria</option>
                  <option value="Sanitaires">Sanitaires</option>
                  <option value="Espace extérieur">Espace extérieur</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  id="officeName"
                  type="text"
                  name="officeName"
                  label="Nom de l'espace"
                  onChange={this.handleChange}
                  value={this.state.officeName}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            {window.innerWidth > 400 ? (
              <>
                <RadioGroup
                  aria-label="position"
                  name="urgence"
                  value={this.state.urgence}
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="Très urgent"
                    control={<Radio color="primary" />}
                    label="Très urgent"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Urgent"
                    control={<Radio color="primary" />}
                    label="Urgent"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Peu urgent"
                    control={<Radio color="primary" />}
                    label="Peu urgent"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Pas urgent"
                    control={<Radio color="primary" />}
                    label="Pas urgent"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Si possible"
                    control={<Radio color="primary" />}
                    label="Si possible"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </>
            ) : (
              <Grid item xs={10} sm={5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <InputLabel htmlFor="age-native-simple">
                    Urgence *
                  </InputLabel>
                  <Select
                    label="Urgence *"
                    native
                    value={this.state.urgence}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "urgence",
                      id: "urgence",
                    }}
                  >
                    <option value=""></option>
                    <option value="Très urgent">Très urgent</option>
                    <option value="Urgent">Urgent</option>
                    <option value="Peu urgent">Peu urgent</option>
                    <option value="Pas urgent">Pas urgent</option>
                    <option value="Si possible">Si possible</option>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={10} sm={5}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  variant="outlined"
                  type="text"
                  name="incidentName"
                  label="5 mots pour le décrire *"
                  onChange={this.handleChange}
                  value={this.state.incidentName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ margin: "1rem auto" }}>
                <InputLabel htmlFor="age-native-simple">
                  Domaine d'expertise *
                </InputLabel>
                <Select
                  label="Domaine d'expertise *"
                  native
                  value={this.state.expertise}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "expertise",
                    id: "expertise",
                  }}
                >
                  <option value="" />
                  <option value="Propreté">Propreté</option>
                  <option value="Sécurité">Sécurité</option>
                  <option value="Détection et Extinction Incendie">Détection et Extinction Incendie</option>
                  <option value="Climatisation et Chauffage">
                    Climatisation et Chauffage
                  </option>
                  <option value="Wifi et Téléphonie">Wifi et Téléphonie</option>
                  <option value="Ascenseurs">Ascenseurs</option>
                  <option value="Courrier">Courrier</option>
                  <option value="Cafétérias">Cafétérias</option>
                  <option value="Plomberie et Electricité">
                    Plomberie, Electricité, Serrurerie, Menuiserie
                  </option>
                  <option value="Mobilier, Décoration et Plantes">
                    Mobilier, Décoration et Plantes
                  </option>
                  <option value="Espaces verts">Espaces verts</option>
                  <option value="Bail">Bail</option>
                  <option value="Déménagements internes">
                    Aménagements et Déménagements
                  </option>
                  <option value="Autres">Autres</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={10} sm={11}>
              <FormControl
                fullWidth
                style={{ margin: "1rem auto" }}>
                <TextField
                  name="description"
                  id="description"
                  label="Description *"
                  multiline
                  maxRows="4"
                  fullWidth
                  value={this.state.description}
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                />
              </FormControl>
            </Grid>
            {this.props.user.status === 1 && (
              <Grid item xs={10} sm={11}>
                <FormControl
                  fullWidth
                  style={{ margin: "1rem auto" }}>
                  <TextField
                    name="comment"
                    id="comment"
                    label="Commentaire"
                    multiline
                    maxRows="4"
                    fullWidth
                    value={this.state.comment}
                    onChange={this.handleChange}
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <FilePond
            name="uploadFile"
            maxFiles={1}
            onupdatefiles={this.onUpdateFiles}
            allowFileTypeValidation
            acceptedFileTypes={["image/png", "image/jpeg"]}
            filepond-plugin-file-validate-type="Format image invalide!"
            labelIdle='Glisser-déposer votre photo ou <span class="filepond--label-action">Parcourir</span>'
          />
          <Grid container justifyContent="center">
            {this.state.error && (
              <em
                style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}
              >
                {this.state.error}
              </em>
            )}
            {this.state.success && (
              <em
                style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}
              >
                {this.state.success}
              </em>
            )}
          </Grid>

          <ButtonWrapper>
            <Button
              disabled={this.state.isLoading}
              onClick={this.submitForm}
              variant="contained"
              style={{
                background: "#1C3144",
                color: "#FFF",
                outline: "none",
                margin: ".3rem",
              }}
            >
              {this.state.isLoading ? "ENVOI..." : "VALIDER"}
            </Button>
          </ButtonWrapper>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default withRouter(
  connect(mapStateToProps, null)(IncidentScreen)
);
