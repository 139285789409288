/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Axios from "axios";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCompanyModal = ({ open, close, wppList, addClient, user }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [wppIds, setWppIds] = useState([]);
  const [state, setState] = useState({
    sendEmail: false,
    // emailList: "",
    // wppId: "",
    // wppBackupId: "",
    nameCompany: "",
    numContract: "",
    numOfEmployeesTotal: "",
    logo: "",
    placePict: "",
    commentContract: ""
  });

  useEffect(() => setError(""), [close]);

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const handleChangeWppIds = (event) => {
    const {
      target: { value },
    } = event;
    setWppIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleStartChange = date => {
    setStartDate(date);
  };

  const handleEndChange = date => {
    setEndDate(date);
  };

  const submitForm = async () => {
    const {
      nameCompany,
      numContract,
      numOfEmployeesTotal
    } = state;

    try {
      if (
        wppIds.length < 1 ||
        !nameCompany ||
        !numContract ||
        !numOfEmployeesTotal
      ) {
        throw new Error("Veuillez remplir tous les champs!");
      }

      setIsLoading(true);
      const newCompany = await Axios.post(
        `${process.env.REACT_APP_API_URL}/companies/add`,
        {
          ...state,
          wppIds,
          dateStartContract: startDate,
          dateEndContract: endDate,
          user
        }
      );

      addClient(newCompany.data);
      setSuccess("Compagnie ajoutée!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
      }, 1500);
    } catch (err) {
      setError(err.message);
    }
  };

  const wppListOption = wppList.map((wpp, i) => {
    const name = (wpp.firstname && wpp.lastname) ? `${wpp.firstname} ${wpp.lastname}` : wpp.email;
    return (
      <MenuItem key={wpp._id} value={wpp._id}>
        {name}
      </MenuItem>
    );
  });

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Ajouter Client
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}>
          {success}
        </em>
      )}

      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={state.sendEmail}
              onChange={handleCheck}
              name="sendEmail"
              color="primary"
            />
          }
          label="Envoyer un mail pour la création d'incident"
        />
        {/* <TextField
          disabled={!state.sendEmail}
          onChange={handleChange}
          name="emailList"
          value={state.emailList}
          type="text"
          label="Emails de contact"
          margin="normal"
          placeholder="adresse1@mail.com, adresse2@mail.com..."
          fullWidth
          variant="filled"
        /> */}
        <Grid container justifyContent="space-around">
          <FormControl style={{ width: "90%", marginBottom: "20px" }}>
            <InputLabel htmlFor="age-native-simple">WPP en charge *</InputLabel>
            <Select
              multiple
              value={wppIds}
              onChange={handleChangeWppIds}
              inputProps={{
                name: "wppId",
                id: "wppId"
              }}
            >
              {wppListOption}
            </Select>
          </FormControl>
          {/* <FormControl style={{ width: "90%", marginBottom: "20px" }}>
            <InputLabel htmlFor="age-native-simple">
              WPP de reserve *
            </InputLabel>
            <Select
              multiple
              value={[state.wppBackupId]}
              onChange={handleChange}
              inputProps={{
                name: "wppBackupId",
                id: "wppBackupId"
              }}
            >
              <MenuItem value=""></MenuItem>
              {wppListOption}
            </Select>
          </FormControl> */}
        </Grid>
        <TextField
          onChange={handleChange}
          value={state.nameCompany}
          name="nameCompany"
          type="text"
          label="Nom de la companie *"
          style={{ margin: "1rem auto 3rem auto" }}
          fullWidth
          variant="filled"
        />
        <DialogContentText color="primary">
          Informations Contrat
        </DialogContentText>
        <TextField
          onChange={handleChange}
          value={state.numContract}
          name="numContract"
          type="text"
          label="Numéro de contrat"
          style={{ margin: "8 auto" }}
          placeholder="numéro de contrat"
          fullWidth
          margin="normal"
          variant="filled"
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Signature de contrat"
              margin="normal"
              value={startDate}
              onChange={handleStartChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              label="Echéance du contrat"
              margin="normal"
              value={endDate}
              onChange={handleEndChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <TextField
          onChange={handleChange}
          value={state.numOfEmployeesTotal}
          name="numOfEmployeesTotal"
          label="Nombre total de salariés"
          type="number"
          style={{ margin: "8 auto" }}
          fullWidth
          margin="normal"
          variant="filled"
        />
        <TextField
          label="Commentaires"
          multiline
          maxRows="4"
          fullWidth
          name="commentContract"
          value={state.commentContract}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button variant="outlined" onClick={submitForm} color="primary">
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default AddCompanyModal;
