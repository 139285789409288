/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddAddressModal = ({ open, close, addAddress, user, companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [state, setState] = useState({
    sendEmail: false,
    emailList: "",
    nameAddress: "",
    street: "",
    spareAddress: "",
    zip: "",
    city: "",
    zone: "",
    numOfEmployees: "",
    surface: "",
    numOfBuilding: "",
    numOfFloor: "",
    roomType: "",
    cafeteria: "",
    outdoorSpace: "",
    commentAddress: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setError("");
    setState({ ...state, [name]: value });
  };

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const submitForm = async () => {
    const {
      nameAddress,
      street,
      city,
    } = state;

    try {
      if (
        !nameAddress ||
        !street ||
        !city
      ) {
        throw new Error("Veuillez remplir tous les champs!");
      }
      setIsLoading(true);
      const addressAdded = await Axios.post(
        `${process.env.REACT_APP_API_URL}/address/add`,
        {
          ...state,
          companyId,
          // wppId: company.wppId,
          // wppIds: company.wppIds,// revoir apres routine // Fait coté backend maintenant
          // company: company.nameCompany,
          user
        }
      );
      setIsLoading(false);

      addAddress(addressAdded.data);

      setSuccess("Adresse ajoutée!");
      setTimeout(() => {
        setSuccess("");
        setError("");
        close();
      }, 1500);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
        Ajouter adresse
      </DialogTitle>
      {error && (
        <em style={{ fontWeight: "bold", margin: "0 auto", color: "red" }}>
          {error}
        </em>
      )}
      {success && (
        <em style={{ fontWeight: "bold", margin: "0 auto", color: "green" }}>
          {success}
        </em>
      )}
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={state.sendEmail}
              onChange={handleCheck}
              name="sendEmail"
              color="primary"
            />
          }
          label="Envoyer un mail pour la création d'incident"
        />
        <TextField
          disabled={!state.sendEmail}
          onChange={handleChange}
          name="emailList"
          value={state.emailList}
          type="text"
          label="Emails de contact"
          margin="normal"
          placeholder="adresse1@mail.com, adresse2@mail.com..."
          fullWidth
          variant="outlined"
        />
        <TextField
          onChange={handleChange}
          name="nameAddress"
          value={state.nameAddress}
          type="text"
          label="Nom de l'adresse"
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <TextField
          onChange={handleChange}
          name="street"
          value={state.street}
          type="text"
          label="Rue"
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <TextField
          onChange={handleChange}
          name="spareAddress"
          value={state.spareAddress}
          type="text"
          label="Complément d'adresse"
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <Grid container justifyContent="space-around">
          <Grid item xs={10} sm={5}>
            <TextField
              onChange={handleChange}
              name="zip"
              value={state.zip}
              type="number"
              inputProps={{ min: "0" }}
              label="Code postal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={10} sm={5}>
            <TextField
              onChange={handleChange}
              name="city"
              value={state.city}
              type="text"
              label="Ville"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <DialogContentText style={{ margin: "1rem 0" }} color="primary">
          Informations Adresse
        </DialogContentText>
        <TextField
          onChange={handleChange}
          name="zone"
          value={state.zone}
          type="text"
          inputProps={{ min: "0" }}
          label="Zone"
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <TextField
          onChange={handleChange}
          name="numOfEmployees"
          value={state.numOfEmployees}
          type="text"
          inputProps={{ min: "0" }}
          label="Nombre d'occupants à cette adresse"
          margin="normal"
          fullWidth
          variant="outlined"
        />

        <DialogContentText style={{ margin: "1rem 0" }} color="primary">
          Détails des locaux
        </DialogContentText>
        <Grid container justifyContent="center">
          <Select
            native
            fullWidth
            value={state.roomType}
            onChange={handleChange}
            inputProps={{
              name: "roomType",
              id: "roomType",
            }}
          >
            <option value="">Type d'espace</option>
            <option value="openSpace">OpenSpace</option>
            <option value="bureau">Bureau</option>
            <option value="mixte">Mixte</option>
          </Select>
        </Grid>
        <Grid container justifyContent="space-around">
          <TextField
            onChange={handleChange}
            name="surface"
            value={state.surface}
            type="number"
            inputProps={{ min: "0" }}
            label="Surface locaux (m2)"
            margin="normal"
            variant="outlined"
          />
          <TextField
            onChange={handleChange}
            name="numOfBuilding"
            value={state.numOfBuilding}
            type="number"
            inputProps={{ min: "0" }}
            label="Nombre de bâtiments"
            margin="normal"
            variant="outlined"
          />
          <TextField
            onChange={handleChange}
            name="numOfFloor"
            value={state.numOfFloor}
            type="number"
            inputProps={{ min: "0" }}
            label="Nombre d'étages"
            margin="normal"
            variant="outlined"
          />
          <TextField
            onChange={handleChange}
            name="cafeteria"
            value={state.cafeteria}
            type="text"
            inputProps={{ min: "0" }}
            label="Cafeteria"
            margin="normal"
            variant="outlined"
          />
          <TextField
            onChange={handleChange}
            name="outdoorSpace"
            value={state.outdoorSpace}
            type="text"
            inputProps={{ min: "0" }}
            label="Espace exterieur"
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid container justifyContent="center">
          <TextField
            name="commentAddress"
            label="Commentaire"
            multiline
            maxRows="4"
            fullWidth
            value={state.commentAddress}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} color="primary">
          Annuler
        </Button>
        <Button variant="outlined" onClick={submitForm} color="primary">
          {isLoading ? "Envoi..." : "Valider"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAddressModal;
