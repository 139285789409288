import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import axios from "axios";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import logo from "../../logoFWPv2.png";
import "../style/UserRegistration.css";
import { withRouter } from "react-router-dom";

class UserRegistrationScreen extends Component {
  constructor() {
    super();
    var loc = new URL(window.location);
    var searchParams = new URLSearchParams(loc.search);
    this.state = {
      email: searchParams.get("email"),
      token: searchParams.get("token"),
      lastname: "",
      firstname: "",
      officePhone: "",
      mobilePhone: "",
      whatsApp: false,
      password: "",
      confirmPassword: "",
      errorMessage: "",
      isLoading: false,
      redirection: null,
      error: "",
      success: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, error: "" });
  };

  submitForm = async () => {
    const {
      email,
      token,
      lastname,
      firstname,
      officePhone,
      mobilePhone,
      whatsApp,
      password,
      confirmPassword,
    } = this.state;
    this.setState({ isLoading: true });
    try {
      if (
        lastname === "" ||
        firstname === "" ||
        whatsApp === "" ||
        password === "" ||
        confirmPassword === ""
      ) {
        throw new Error("Remplissez correctement tous les champs avec *");
      }
      if (password !== confirmPassword) {
        throw new Error(
          "Le mot de passe et sa confirmation ne sont pas identiques!"
        );
      }
      if (password.length < 8) {
        throw new Error(
          "Votre mot de passe doit contenir au moins 8 caractères!"
        );
      }
      if (!/.*[a-z]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins une minuscule!"
        );
      }
      if (!/.*[A-Z]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins une majuscule!"
        );
      }
      if (!/.*[0-9]/.test(password)) {
        throw new Error(
          "Votre mot de passe doit contenir au moins un chiffre!"
        );
      }
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/register/${token}`,
        {
          email,
          lastname,
          firstname,
          officePhone,
          mobilePhone,
          whatsApp,
          password,
          token,
          confirmPassword,
        }
      );
      this.setState({ success: "Enregistrement effectué" });
      setTimeout(() => {
        this.setState({ success: "" });
        this.props.history.push("/");
      }, 1500);
    } catch (error) {
      this.setState({ error: error.message });
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Jumbotron
        className="Jumbotron1"
        style={{ height: "100vh", marginBottom: "0px" }}
      >
        <Container maxWidth="sm" style={{padding: '3rem'}}>
          <Grid container justifyContent="space-around">
            <img
              style={{ width: "80%" }}
              src={logo}
              alt="Logo"
            />
            <FormControl style={{ width: "80%", marginBottom: "15px" }}>
              <TextField
                disabled={true}
                name="email"
                id="email"
                type="text"
                label="Email enregistré"
                value={this.state.email}
              />
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="firstname"
                id="firstname"
                type="text"
                label="Prénom *"
                value={this.state.firstname}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="lastname"
                id="lastname"
                type="text"
                label="Nom *"
                value={this.state.lastname}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="mobilePhone"
                id="mobilePhone"
                type="text"
                label="Téléphone portable"
                value={this.state.mobilePhone}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="officePhone"
                id="officePhone"
                type="text"
                label="Téléphone fixe"
                value={this.state.officePhone}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "80%", marginBottom: "15px" }}>
              <InputLabel htmlFor="age-native-simple">
                Utilisez-vous Whatsapp *
              </InputLabel>
              <Select
                native
                value={this.state.whatsApp}
                onChange={this.handleChange}
                inputProps={{
                  name: "whatsApp",
                  id: "whatsApp",
                }}
              >
                <option value=""></option>
                <option value={false}>Non</option>
                <option value={true}>Oui</option>
              </Select>
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="password"
                id="password"
                type="password"
                label="Password *"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl style={{ width: "40%", marginBottom: "15px" }}>
              <TextField
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                label="Confirmation du password *"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
            </FormControl>
            {this.state.error && (
              <em
                style={{ fontWeight: "bold", color: "red", margin: "0 auto" }}
              >
                {this.state.error}
              </em>
            )}
            {this.state.success && (
              <em
                style={{ fontWeight: "bold", color: "green", margin: "0 auto" }}
              >
                {this.state.success}
              </em>
            )}
            <FormControl style={{ width: "80%" }}>
              <button
                disabled={this.state.isLoading}
                className="form-contact-button"
                onClick={this.submitForm}
              >
                {this.state.isLoading ? "Envoi..." : "Valider"}
              </button>
              <p>Le mot de passe doit contenir au moins 8 caractères contenant au minimum une minuscule, une majuscule et un chiffre</p>
              <p>* champ obligatoire</p>
            </FormControl>
          </Grid>
        </Container>
      </Jumbotron>
    );
  }
}
export default withRouter(UserRegistrationScreen);
