import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, orange, yellow, green, blue } from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CreateIcon from "@material-ui/icons/Create";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AlarmIcon from "@material-ui/icons/Alarm";
import { dateFormat, textFormat } from "../helpers";
import { Divider } from "@material-ui/core";

const screenSize = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  rootclose: {
    backgroundColor: "#F1F1F1",
    margin: 15,
    width: 345,
    height: screenSize < 400 ? "fit-content" : 310,
  },
  rootopen: {
    margin: 15,
    width: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  urgenceRed: {
    backgroundColor: red[500],
  },
  urgenceOrange: {
    backgroundColor: orange[800],
  },
  urgenceYellow: {
    backgroundColor: yellow[700],
  },
  urgenceGreen: {
    backgroundColor: green[500],
  },
  urgenceBlue: {
    backgroundColor: blue[500],
  },
  header: {
    color: "rgb(55, 156, 136)",
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  date: {
    color: "#1C3144",
  },
  name: {
    fontSize: "1rem",
  },
  status: {
    color: "#1C3144",
    fontWeight: "500",
  },
}));

export default function IncidentCard({
  incident,
  user,
  userIncident,
  readOnlyModal,
  updateModal,
  processModal,
}) {
  const {
    companyName,
    addressName,
    incidentName,
    description,
    urgence,
    historicalStatus,
    currentStatus,
  } = incident;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let openningDate =
    historicalStatus.length === 0
      ? currentStatus[0].dateOpen
      : historicalStatus[0].dateOpen;
  let urgenceColor;
  switch (urgence) {
    case "Très urgent":
      urgenceColor = classes.urgenceRed;
      break;
    case "Urgent":
      urgenceColor = classes.urgenceOrange;
      break;
    case "Peu urgent":
      urgenceColor = classes.urgenceYellow;
      break;
    case "Pas urgent":
      urgenceColor = classes.urgenceGreen;
      break;
    case "Si possible":
      urgenceColor = classes.urgenceBlue;
      break;
    default:
      urgenceColor = "";
  }
  var valueProgressBar;
  switch (currentStatus[0].status) {
    case "Incident signalé":
      valueProgressBar = 10;
      break;
    case "Incident pris en charge":
      valueProgressBar = 25;
      break;
    case "Intervention planifiée":
      valueProgressBar = 50;
      break;
    case "Intervention réalisée":
      valueProgressBar = 75;
      break;
    case "Incident clos":
      valueProgressBar = 100;
      break;
    default:
      valueProgressBar = 0;
  }
  let title = "";
  if (user.status === 1)
    title = (
      <span>
        {companyName ? textFormat(companyName, 18, 25, screenSize < 400) : ""}
        <br />
        {addressName ? textFormat(addressName, 18, 25, screenSize < 400) : ""}
      </span>
    );

  if (user.status === 2 || user.status === 5) title = `${addressName}`;

  const renderColorFont = (urgence) => {
    switch (urgence) {
      case "Très urgent":
        return "#f44336";
      case "Urgent":
        return "#ef6c00";
      case "Peu urgent":
        return "#fbc02d";
      case "Pas urgent":
        return "#4caf50";
      case "Si possible":
        return "#03a9f4";
      default:
        return "#000";
    }
  };

  return (
    <Card className={expanded ? classes.rootopen : classes.rootclose}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={urgenceColor}>
            <Typography
              style={{ fontWeight: "600" }}
              variant="body2"
              component="p"
            >
              {valueProgressBar}%
            </Typography>
            {/* {companyName.slice(0, 1)} */}
          </Avatar>
        }
        title={
          <Typography className={classes.header} variant="body2" component="p">
            {title}
          </Typography>
        }
        subheader={
          <Typography className={classes.date} variant="caption" component="p">
            {dateFormat(openningDate)}{" "}
            {userIncident.firstname &&
              `par ${userIncident.firstname.slice(0, 1)}. ${textFormat(userIncident.lastname, 100, 25, screenSize < 400)}`}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Typography
          style={{
            color: renderColorFont(urgence),
            fontStyle: "italic",
            fontSize: "1.1rem",
          }}
          variant="body2"
          component="p"
        >
          <AlarmIcon /> {urgence}
        </Typography>
        {currentStatus[0].status === "Intervention planifiée" ? (
          <Typography
            className={classes.status}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {currentStatus[0].status} le :{" "}
            {dateFormat(currentStatus[0].dateInter)}
          </Typography>
        ) : (
          <Typography
            className={classes.status}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {currentStatus[0].status} le :{" "}
            {dateFormat(currentStatus[0].dateOpen)}
          </Typography>
        )}

        <Divider />
        <br />
        <Typography
          className={classes.name}
          variant="h6"
          color="textSecondary"
          component="h6"
        >
          {textFormat(incidentName, 26, 30, screenSize < 400).toUpperCase()}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {textFormat(description, 35, 45, screenSize < 400)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="show" onClick={() => readOnlyModal(incident)}>
          <VisibilityIcon />
        </IconButton>
        {(user.id === incident.userId || user.status !== 4) && (
          <IconButton aria-label="update" onClick={() => updateModal(incident)}>
            <CreateIcon />
          </IconButton>
        )}
        {(user.status === 1 || user.status === 5 || user.status === 6) && (
          <IconButton aria-label="treat" onClick={() => processModal(incident)}>
            <CheckCircleOutlineIcon />
          </IconButton>
        )}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          style={{ outline: "none" }}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {incident.attachment && (
          <CardMedia className={classes.media} image={incident.attachment} />
        )}
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="h6">
            {incidentName}
          </Typography>
          <Typography paragraph>{description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
