import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import PersonIcon from "@material-ui/icons/Person";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import CloseIcon from "@material-ui/icons/Close";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MessageIcon from "@material-ui/icons/Message";

import AppsIcon from "@material-ui/icons/Apps";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ProfilModal from "../ProfilModal";

const drawerWidth = 115;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 1,
    },
  },
  appBar: {
    minHeight: 105,

    placeContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
  },
  menuButton: {
    color: "#1C3144",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    minHeight: 135,
  },
  drawerPaper: {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  profileName: {
    color: "rgb(55, 156, 136)",
    "@media (max-width: 650px)": {
      display: "none",
    },
  },
  profileIcon: {
    fontSize: 30,
    "overflow-y": "scroll",
    color: "#1C3144",
    "@media (max-width: 650px)": {
      marginRight: "3rem",
    },
  },
  closeButton: {
    float: "right",
    color: "#1C3144",
    margin: ".7rem",
    "@media (min-width: 550px)": {
      display: "none",
    },
  },
  companyName: {
    color: "rgb(55, 156, 136)",
    fontWeight: 500,
  },
  logo: {
    cursor: "pointer",
    width: "20%",

    "@media (max-width: 650px)": {
      display: "none",
    },
  },
  listItemIcon: {
    justifyContent: "flex-end",
  },
  icon: {
    margin: "1rem 0",
    fontSize: 35,
    color: "#1C3144",
  },
}));

const NavMenu = ({ container, history, user, logout }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [logoutVersionModal, setLogoutVersionModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  /// TEST DE LA VERSION DE L'UTILISATEUR A MODIFIER EN MEME TEMPS QUE LA VERSION DANS LE BACKEND const version dans users.js
  if (user.version !== "1.1.1" && !logoutVersionModal) {
    setLogoutVersionModal(true)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setProfileModal(true);
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
    history.push("/");
  };

  const renderIcon = (text) => {
    switch (text) {
      case "News":
        return <AnnouncementIcon className={classes.icon} />;
      case "Déclarer Incident":
        return <AddAlertIcon className={classes.icon} />;
      case "Liste Clients":
        return <PeopleAltIcon className={classes.icon} />;
      case "Messages":
        return <MessageIcon className={classes.icon} />;
      default:
        return <HomeIcon className={classes.icon} />;
    }
  };

  const onClickMenu = (text) => {
    switch (text) {
      case "News":
        return history.push("/newsscreen");
      case "Déclarer Incident":
        return history.push("/incidentscreen");
      case "Liste Clients":
        return user.status === 1
          ? history.push("/clientlistscreen")
          : history.push({
            pathname: "/addressList",
            state: { company: user.companies[0], addresses: user.addresses },
          });
      case "Messages":
        return history.push("/messageScreen");
      default:
        return;
    }
  };

  let iconList =
    user.status !== 4
      ? ["News", "Déclarer Incident", "Liste Clients", "Messages"]
      : ["News", "Déclarer Incident"];

  const renderProfileName = () =>
    `${user.firstName} ${user.lastName.charAt(0)}.`;

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <CloseIcon
          onClick={handleDrawerToggle}
          className={classes.closeButton}
        />
      </div>

      <List>
        {iconList.map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon
              onClick={() => onClickMenu(text)}
              className={classes.listItemIcon}
            >
              {renderIcon(text)}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <AppsIcon />
          </IconButton>

          <div
            style={{
              minWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img className={classes.logo} alt="LogoWP" src="./logoFWPv2.png" />

            <Typography className={classes.companyName} variant="h4">
              {user.status === 1
                ? "FabWorkplace"
                : user.nameCompany}
            </Typography>
            <Button
              className={classes.profileButton}
              style={{ outline: "none" }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <PersonIcon className={classes.profileIcon} />
              &nbsp;
              <span className={classes.profileName}>{renderProfileName()}</span>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleProfile}>
                <AccountBoxIcon /> Profil
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <ExitToAppIcon /> Déconnexion
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <ProfilModal
        open={profileModal}
        close={() => setProfileModal(false)}
        user={user}
      />
      <Dialog
        open={logoutVersionModal}
        onClose={() => setLogoutVersionModal(false)}
      >
        <DialogTitle style={{ fontWeight: "bold", color: "#379C88" }}>
          Nouvelle version disponible
        </DialogTitle>
        <DialogContent>
          Vous devez vous déconnecter pour mettre à jour l'application
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleLogout()}
            variant="outlined"
            color="primary"
          >
            Déconnexion
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavMenu)
);
