const initialState = {
  incidents: [],
  addresses: [],
  companies: [],
  users: [],
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  officePhone: "",
  whatsapp: false,
  companyId: "",
  addressId: "",
  status: null,
  version: "",
  token: ""
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_USER":
      return {
        ...state,
        id: payload._id,
        firstName: payload.firstname,
        lastName: payload.lastname,
        email: payload.email,
        mobilePhone: payload.mobilePhone,
        officePhone: payload.officePhone,
        whatsapp: payload.whatsApp,
        companyId: payload.companyId,
        addressId: payload.addressId,
        status: payload.status,
        version: payload.version,
        nameCompany: payload.nameCompany,
        token: payload.token,
      };
    case "UPDATE_PROFIL":
      return {
        ...state,
        firstName: payload.firstname,
        lastName: payload.lastname,
        email: payload.email,
        mobilePhone: payload.mobilePhone,
        officePhone: payload.officePhone,
        whatsapp: payload.whatsApp,
      };
    case "ADD_COMPANY":
      return {
        ...state,
        companies: [...state.companies, payload.company],
      };
    case "UPDATE_COMPANY":
      const updatedCompanies = [
        ...state.companies.filter(
          (company) => company._id !== payload.company._id
        ),
        payload.company,
      ];
      return {
        ...state,
        companies: updatedCompanies,
      };
    case "ADD_INCIDENT":
      return {
        ...state,
        incidents: [...state.incidents, payload.incident],
      };
    case "UPDATE_INCIDENT":
      const updatedIncidents = [
        ...state.incidents.filter(
          (incident) => incident._id !== payload.incident._id
        ),
        payload.incident,
      ];
      return {
        ...state,
        incidents: updatedIncidents,
      };
    case "SET_INCIDENTS":
      return {
        ...state,
        incidents: payload.incidents,
      };
    case "ADD_ADDRESS":
      return {
        ...state,
        addresses: [...state.addresses, payload.address],
      };
    case "UPDATE_ADDRESS":
      const updatedAddresses = [
        ...state.addresses.filter(
          (address) => address._id !== payload.address._id
        ),
        payload.address,
      ];
      return {
        ...state,
        addresses: updatedAddresses,
      };
    case "SET_ADDRESSES":
      return {
        ...state,
        addresses: payload.addresses,
      };
    case "ADD_MESSAGE":
      return {
        ...state,
        messages: [...state.messages, payload.message],
      };
    case "DEL_MESSAGE":
      const deletedMessage = [
        ...state.messages.filter(
          (message) => message._id !== payload.message._id
        ),
      ];
      return {
        ...state,
        messages: deletedMessage,
      };
    case "UPDATE_MESSAGE":
      const updatedMessages = [
        ...state.messages.filter(
          (message) => message._id !== payload.message._id
        ),
        payload.message,
      ];
      return {
        ...state,
        messages: updatedMessages,
      };
    case "SET_MESSAGES":
      return {
        ...state,
        messages: payload.messages,
      };
    case "ADD_USER":
      return {
        ...state,
        users: [...state.users, payload.user],
      };
    case "ADD_USER_LIST":
      return {
        ...state,
        users: [...state.users, ...payload.userList],
      };
    case "UPDATE_USER":
      const updatedUsers = [
        ...state.users.filter((user) => user._id !== payload.user._id),
        payload.user,
      ];
      return {
        ...state,
        users: updatedUsers,
      };
    case "SET_USERS_LIST":
      return {
        ...state,
        users: payload.users,
      };
    case "DEL_USER":
      const deletedUser = [
        ...state.users.filter((user) => user._id !== payload.user._id),
      ];
      return {
        ...state,
        users: deletedUser,
      };
    case "RESET_USER":
      return initialState;
    default:
      return state;
  }
};

export default user;
