import React from "react";
import axios from "axios";
import { userActions } from "../../actions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import AddMessageModal from "../AddMessageModal";
import ModifMessageModal from "../ModifMessageModal";
import DeleteMessageModal from "../DeleteMessageModal";
import { Container } from "@material-ui/core";
import NavMenu from "../Navs/NavMenu";
import Loader from "../Loader";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MessageCard from "../MessageCard";

const Wrapper = styled.div`
  margin: 6.8rem auto auto 7rem;
  padding: 1rem;
  background-color: #f9f0f1;

  @media screen and (max-width: 660px) {
    margin: 6.8rem auto auto auto;
  }
`;

const Title = styled(Typography)`
  color: #1c3144;
`;

class MessageScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      addresses: [],
      companies: [],
      messageClicked: {},
      deleteModal: false,
      addModal: false,
      modifModal: false,
      isLoading: true,
      success: "",
      error: "",
    };
  };

  async componentDidMount() {
    try {
      const messages = await axios.get(
        `${process.env.REACT_APP_API_URL}/messages?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const addresses = await axios.get(
        `${process.env.REACT_APP_API_URL}/address?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      const companies = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies?id=${this.props.user.id}&token=${this.props.user.token}`
      );
      this.setState({
        messages: messages.data,
        addresses: addresses.data,
        companies: companies.data,
        isLoading: false
      });
    } catch (error) {
      let errorMessage = error?.response?.data || error?.response || error?.message
      if (errorMessage === "Erreur token, déconnexion...") {
        this.setState({ error: errorMessage })
        setTimeout(() => {
          this.props.history.push("/");
          this.props.logout();
        }, 2000);
      }
      console.log('error :>> ', error);
    }
  }

  addMessage = message => {
    this.setState({ messages: [...this.state.messages, message] })
  }

  deleteMessage = message => {
    let mess = [...this.state.messages].filter(m => m._id !== message._id)
    this.setState({ messages: mess })
  }

  updateMessage = message => {
    let messIds = this.state.messages.map(m => m._id)
    let index = messIds.indexOf(message._id)
    let mess = [...this.state.messages]
    mess[index] = message
    this.setState({ messages: mess })
  }

  render() {
    const messageClick = (message, target) => {
      this.setState({ messageClicked: message, [target]: true });
    };
    const sortedMessage = this.state.messages.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    const messages = sortedMessage.map((message) => {
      let companyMessage = this.state.companies.filter(
        (company) => company._id === message.companyId
      );
      let addressMessage = this.state.addresses.filter(
        (address) => address._id === message.addressId
      );
      return (
        <MessageCard
          companyMessage={companyMessage[0]}
          addressMessage={addressMessage[0]}
          userStatus={this.props.user.status}
          key={message._id}
          messageObj={message}
          messageClick={messageClick}
        />
      );
    });
    return (
      <Wrapper>
        <NavMenu />
        {this.state.isLoading ?
          <>
            <Grid container justifyContent="flex-end">
              {this.state.error && (
                <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                  {this.state.error}
                </em>
              )}
            </Grid>
            <Loader />
          </> :
          <Container>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <Title variant="h4">Vos Messages</Title>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-end">
                  <Button
                    endIcon={<AddCircleOutlineIcon />}
                    size="small"
                    onClick={() => this.setState({ addModal: true })}
                    variant="contained"
                    style={{
                      background: "#1C3144",
                      color: "#FFF",
                      outline: "none",
                      margin: "0.5rem",
                    }}
                    disabled={this.state.isLoading}
                  >
                    Nouveau message
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              {this.state.error && (
                <em style={{ fontWeight: "bold", color: "red", margin: " auto" }}>
                  {this.state.error}
                </em>
              )}
              {this.state.success && (
                <em
                  style={{ fontWeight: "bold", color: "green", margin: "auto" }}
                >
                  {this.state.success}
                </em>
              )}
            </Grid>
            <Grid container justifyContent="space-around">
              {messages}
            </Grid>
          </Container>
        }
        <AddMessageModal
          addMessage={this.addMessage}
          addresses={this.state.addresses}
          companies={this.state.companies}
          user={this.props.user}
          open={this.state.addModal}
          close={() => this.setState({ addModal: false })}
        />
        <DeleteMessageModal
          user={this.props.user}
          deleteMessage={this.deleteMessage}
          open={this.state.deleteModal}
          close={() => this.setState({ deleteModal: false })}
          messageClicked={this.state.messageClicked}
        />
        <ModifMessageModal
          updateMessage={this.updateMessage}
          addresses={this.state.addresses}
          companies={this.state.companies}
          user={this.props.user}
          open={this.state.modifModal}
          close={() => this.setState({ modifModal: false })}
          messageClicked={this.state.messageClicked}
        />
      </Wrapper>
    );
  }
}
// ZONE REDUX
const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {
  logout: userActions.resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageScreen);
