const setUser = (
  _id,
  firstname,
  lastname,
  email,
  mobilePhone,
  officePhone,
  whatsApp,
  companyId,
  addressId,
  status,
  version,
  nameCompany,
  token
) => (dispatch) =>
    dispatch({
      type: "SET_USER",
      payload: {
        _id,
        firstname,
        lastname,
        email,
        mobilePhone,
        officePhone,
        whatsApp,
        companyId,
        addressId,
        status,
        version,
        nameCompany,
        token
      },
    });

const updateProfil = (
  firstname,
  lastname,
  email,
  mobilePhone,
  officePhone,
  whatsApp
) => (dispatch) =>
    dispatch({
      type: "UPDATE_PROFIL",
      payload: {
        firstname,
        lastname,
        email,
        mobilePhone,
        officePhone,
        whatsApp,
      },
    });

const addCompany = (company) => (dispatch) =>
  dispatch({
    type: "ADD_COMPANY",
    payload: { company },
  });

const updateCompany = (company) => (dispatch) =>
  dispatch({
    type: "UPDATE_COMPANY",
    payload: { company },
  });

const addIncident = (incident) => (dispatch) =>
  dispatch({
    type: "ADD_INCIDENT",
    payload: { incident },
  });

const updateIncident = (incident) => (dispatch) =>
  dispatch({
    type: "UPDATE_INCIDENT",
    payload: { incident },
  });

const setIncidents = (incidents) => (dispatch) =>
  dispatch({
    type: "SET_INCIDENTS",
    payload: { incidents },
  });

const addAddress = (address) => (dispatch) =>
  dispatch({
    type: "ADD_ADDRESS",
    payload: { address },
  });

const updateAddress = (address) => (dispatch) =>
  dispatch({
    type: "UPDATE_ADDRESS",
    payload: { address },
  });

const setAddresses = (addresses) => (dispatch) =>
  dispatch({
    type: "SET_ADDRESSES",
    payload: { addresses },
  });

const addMessage = (message) => (dispatch) =>
  dispatch({
    type: "ADD_MESSAGE",
    payload: { message },
  });

const deleteMessage = (message) => (dispatch) =>
  dispatch({
    type: "DEL_MESSAGE",
    payload: { message },
  });

const updateMessage = (message) => (dispatch) =>
  dispatch({
    type: "UPDATE_MESSAGE",
    payload: { message },
  });

const setMessages = (messages) => (dispatch) =>
  dispatch({
    type: "SET_MESSAGES",
    payload: { messages },
  });

const addUser = (user) => (dispatch) =>
  dispatch({
    type: "ADD_USER",
    payload: { user },
  });

const addUserList = (userList) => (dispatch) =>
  dispatch({
    type: "ADD_USER_LIST",
    payload: { userList },
  });

const updateUser = (user) => (dispatch) =>
  dispatch({
    type: "UPDATE_USER",
    payload: { user },
  });

const setUsersList = (users) => (dispatch) =>
  dispatch({
    type: "SET_USERS_LIST",
    payload: { users },
  });

const deleteUser = (user) => (dispatch) =>
  dispatch({
    type: "DEL_USER",
    payload: { user },
  });

const resetUser = () => (dispatch) =>
  dispatch({
    type: "RESET_USER",
  });

const actionsReducer = {
  setUser,
  setIncidents,
  setAddresses,
  setMessages,
  setUsersList,
  deleteUser,
  resetUser,
  addIncident,
  updateIncident,
  addAddress,
  updateAddress,
  addMessage,
  updateMessage,
  deleteMessage,
  addUser,
  addUserList,
  updateUser,
  addCompany,
  updateCompany,
  updateProfil,
};

export default actionsReducer
